export const USER_ID_IDENTIFIER = '@loophealth';
interface ISegmentAct {
    name: string;
    properties?: Record<string, unknown>;
}
interface ISegmentActions extends Record<string, unknown> {
    PAGE: Record<string, ISegmentAct>;
    CLICK: Record<string, ISegmentAct>;
    IDENTIFY: Record<string, ISegmentAct>;
    TASK: Record<string, ISegmentAct>;
}
export const SEGMENT_ACTIONS: ISegmentActions = {
    PAGE: {
        LOGIN_PAGE: {
            name: 'LOGIN PAGE',
            properties: {
                page_url: (path: string) => path,
                page_id: (path: string) => path,
                login_status: false
            }
        },
        CLAIM_DIRECTORY: {
            name: 'CLAIM DIRECTORY PAGE',
            properties: {
                page_url: (path: string) => path,
                page_id: (path: string) => path,
                login_status: true
            }
        },
        ADD_CLAIM: {
            name: 'ADD CLAIM PAGE',
            properties: {
                page_url: (path: string) => path,
                page_id: (path: string) => path,
                login_status: true
            }
        },
        CLAIM_DETAILS: {
            name: 'CLAIM DETAILS PAGE',
            properties: {
                page_url: (path: string) => path,
                page_id: (path: string) => path,
                login_status: true
            }
        }
    },
    CLICK: {
        select_company_filter: {
            name: 'select_company_filter',
            properties: {
                description: 'Company filter selected by the user',
                page_title: 'claims_dashboard_app',
                login_status: true,
                company_name_selected: (compName: string) => compName
            }
        },
        select_claimtype_filter: {
            name: 'select_claimtype_filter',
            properties: {
                description: 'User has clicked on a select_claimtype_filter',
                page_title: 'claims_dashboard_app',
                login_status: true,
                claim_type_selected: (claimType: string) => claimType
            }
        },
        select_status_filter: {
            name: 'select_status_filter',
            properties: {
                description: 'User has clicked on a select_status_filter',
                page_title: 'claims_dashboard_app',
                login_status: true,
                status_type_selected: (statusType: number) => statusType
            }
        },
        select_assignto_filter: {
            name: 'select_assignto_filter',
            properties: {
                description: 'User has clicked on a select_assignto_filter',
                page_title: 'claims_dashboard_app',
                login_status: true,
                assignto_selected: (assignedTo: string) => assignedTo
            }
        },
        select_emp_filter: {
            name: 'select_emp_filter',
            properties: {
                description: 'User has clicked on a select_emp_filter',
                page_title: 'claims_dashboard_app',
                login_status: true,
                empname_selected: (empName: string) => empName
            }
        },
        claim_status_filter: {
            name: 'claim_status_filter',
            properties: {
                description: 'User has clicked on a claim_status_filter',
                page_title: 'claims_dashboard_app',
                login_status: true,
                status_type_selected: (statusSelected: string) => statusSelected
            }
        },
        claim_status_update: {
            name: 'claim_status_filter',
            properties: {
                description: 'User has clicked on a claim_status_filter',
                page_title: 'claims_dashboard_app',
                login_status: true,
                status_type_selected: (statusSelected: string) => statusSelected,
                claim_id: (claimId: number) => claimId
            }
        },
        loop_claim_status_update: {
            name: 'loop_claim_status_filter',
            properties: {
                description: 'User has clicked on a loop_claim_status_filter',
                page_title: 'claims_dashboard_app',
                login_status: true,
                status_type_selected: (statusSelected: string) => statusSelected,
                claim_id: (claimId: number) => claimId
            }
        },
        add_claim: {
            name: 'add_claim',
            properties: {
                description: 'User has clicked on a add_claim',
                page_title: 'claims_dashboard_app',
                login_status: true
            }
        },
        claim_details: {
            name: 'claim_details',
            properties: {
                description: 'User has clicked on a claim_details',
                page_title: 'claims_dashboard_app',
                login_status: true,
                claim_id: (claimId: number) => claimId
            }
        }
    },
    TASK: {
        LOGIN_SUCCESS: {
            name: 'LOGIN_SUCCESS',
            properties: {
                login_status: true,
                task: 'login',
                status: true,
                login_method: (loginMethod: string) => loginMethod
            }
        },
        LOGIN_FAIL: {
            name: 'LOGIN_FAIL',
            properties: {
                login_status: false,
                task: 'login',
                status: false,
                login_method: (loginMethod: string) => loginMethod
            }
        }
    },
    IDENTIFY: {
        USER_LOGIN: { name: 'USER_LOGIN' },
        USER_LOGOUT: { name: 'USER_LOGOUT' }
    }
};
