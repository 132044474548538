import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LoginPage } from './components/pages';
import firebase from 'firebase';
import { ILoginContainer } from './types';
import { StyledContainer, StyledPanelContainer, StyledContentContainer } from './styles';
import { checkUserAccess, getUserDetails } from './redux/slices/UserSlice';
import useToken from './utils/hooks/useToken';
import { ErrorContainer } from '../../atoms';
import { Loader } from './components/atoms';
import useSegment from '../../../utils/hooks/useSegment';

declare global {
    interface Window {
        recaptchaVerifier: firebase.auth.RecaptchaVerifier;
        confirmationResult: firebase.auth.ConfirmationResult | null;
        analytics: any;
    }
}
const LoginContainer: React.FunctionComponent<ILoginContainer> = ({ PanelComponent, title, subTitle, redirectTo }) => {
    // const navigate = useHistory();
    const dispatch = useDispatch();
    const authToken = useToken();
    const userData = useSelector((state: any) => state.user.userData);
    const authenticationError = useSelector((state: any) => state.user.isUserAuthenticated.error);
    const [isPersistanceLoading, setIsPersistanceLoading] = React.useState(false);
    const [error, setError] = React.useState('');
    const trackLogin = useSegment('identify');

    // check for persistance
    React.useEffect(() => {
        firebase.auth().onAuthStateChanged((user) => {
            if (user) {
                // User is signed in.
                if (!userData.data) {
                    const email = user.email;
                    if (email) {
                        setIsPersistanceLoading(true);
                        dispatch(getUserDetails({ key: 'email', value: email }));
                    }
                }
            }
        });
    }, [firebase]);

    // Remove the loading screen when an error is received and display the error
    React.useEffect(() => {
        setIsPersistanceLoading(false);
    }, [userData.error, authenticationError]);

    // trigger only if we have aquired userData from firestore
    // 1. When OTP verification is successful
    // 2. When OAuth Login is successful
    React.useEffect(() => {
        if (userData && userData.data) {
            const email = userData.data.email;
            if (email) {
                authToken
                    .generateToken()
                    .then(() => {
                        dispatch(
                            checkUserAccess({
                                redirectFunction: redirectTo,
                                roles: userData.data.roles,
                                setIsLoading: setIsPersistanceLoading,
                                trackLogin
                            })
                        );
                    })
                    .catch(() => {
                        setError('Unable to login user at this time. Please refresh the page and try again.');
                    });
            }
        }
    }, [history, userData]);

    return (
        <StyledContainer>
            <StyledPanelContainer>
                <PanelComponent />
            </StyledPanelContainer>
            <StyledContentContainer>
                {isPersistanceLoading || userData.loading ? (
                    <Loader />
                ) : (
                    <>
                        <LoginPage title={title} subTitle={subTitle} />
                        {!!error?.length && <ErrorContainer message={error} />}
                        {!!authenticationError && <ErrorContainer message={authenticationError.message} />}
                    </>
                )}
            </StyledContentContainer>
        </StyledContainer>
    );
};

export default LoginContainer;
