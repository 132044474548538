import { AgentClaimMap, ClaimEntry, ClaimRecord, CountResult, GroupedClaimStatus, GroupedTpaStatus } from './types';
import { IBadgeType } from '@loophealth/loop-ui-web-library/dist/types/atoms/LoopBadge/types';
import { ClaimNotification } from '../../../components/containers/ClaimStatusSection/types';
import moment from 'moment';
export const extractClaimDetails = (data: ClaimRecord[]): CountResult => {
    const claimStatusCounts: GroupedClaimStatus = {};
    const tpaStatusCounts: GroupedTpaStatus = {};
    let nullAssigneeNameCount = 0;
    for (const record of data) {
        if (record.assigneeId === null) {
            nullAssigneeNameCount++;
        }
        for (const status of record.claimStatus) {
            claimStatusCounts[status.status] = (claimStatusCounts[status.status] || 0) + 1;
        }
        tpaStatusCounts[record.tpaStatus] = (tpaStatusCounts[record.tpaStatus] || 0) + 1;
    }
    return { claimStatusCounts, tpaStatusCounts, nullAssigneeNameCount };
};

export const getTpaStrapDetails = (claimNotification: ClaimNotification[]): { type: IBadgeType; message: string } => {
    if (claimNotification.length) {
        if (claimNotification[0].status === 'FAILED' || claimNotification[0].status === 'ERROR') {
            return {
                type: 'error',
                message: claimNotification[0].error
            };
        } else if (
            claimNotification[0].status === 'SUCCESS' ||
            claimNotification[0].status === 'DELIVERED' ||
            claimNotification[0].status === 'READ'
        ) {
            const date = moment(claimNotification[0].createdAt);
            const formattedDate = date.format('Do MMMM YYYY h:mm A');
            return {
                type: 'success',
                message: 'WhatsApp update sent to the user on ' + formattedDate
            };
        }
    }
    return {
        type: 'success',
        message: ''
    };
};

export const claimUnAssignData = (claimData: ClaimEntry[]): AgentClaimMap[] => {
    const agentClaimMap = new Map();
    claimData.forEach((entry) => {
        if (entry.assignee && entry.assignee.id) {
            const agentId = entry.assignee.id;
            const claimId = entry.id;
            if (agentClaimMap.has(agentId)) {
                agentClaimMap.get(agentId).claimIds.push(claimId);
            } else {
                agentClaimMap.set(agentId, { agentId, claimIds: [claimId] });
            }
        }
    });
    return Array.from(agentClaimMap.values());
};
