export const DEFAULT_PAGE_SIZE = 30;
export const LOCAL_STORAGE_ACTIONABLE_FILTER_STATE_KEY = 'actionableFilterState';

// export const CLAIMS_FILTER_PARAMS = {
//     claimId: '',
//     polHolderName: '',
//     assignTo: '',
//     statusName: '',
//     priBeneficiaryName: '',
//     startDate: new Date(new Date().setDate(new Date().getDate() - 29)),
//     endDate: new Date(),
//     claimType: '',
//     pageSize: DEFAULT_PAGE_SIZE,
//     pageNo: 0
// };

export type ClaimsFilterParams = {
    search: string;
    type: string;
    status: string;
    assignTo: string[];
    priority: string;
    sdate: Date;
    edate: Date;
    perPage: number;
    page: number;
    actionableClaims?: boolean;
    agentId?: string
};
export const CLAIMS_FILTER_PARAMS: ClaimsFilterParams = {
    search: '',
    type: '',
    status: '',
    assignTo: [],
    priority: '',
    sdate: new Date(new Date().setDate(new Date().getDate() - 29)),
    edate: new Date(),
    perPage: DEFAULT_PAGE_SIZE,
    page: 1,
    actionableClaims: true,
    agentId: ''
};

export type IJSON = {
    [key: string]: any;
};
export const ClaimStatus: IJSON = [
    { name: 'Under Progress', value: 'under progress' },
    { name: 'Under Progress (Query) ', value: 'under progress (query)' },
    { name: 'Processed ', value: 'processed' },
    { name: 'settled ', value: 'settled' },
    { name: 'Repudiated ', value: 'repudiated' },
    { name: 'Closed ', value: 'closed' }
];

export const TPA = {
    HEALTH_INDIA: 'Health India TPA',
    MEDIASSIST: 'Medi Assist Insurance India TPA'
};

export const ClaimPriority = {
    VIP: 'vip',
    TAT_EXCEED: 'tat_exceed'
};

export const pageSizeOptions = [10, 25, 50, 100, 200, 500];
