import React from 'react';
import Lottie from 'lottie-react';
import { StyledContainer, StyledLogo, StyledParaContainer, StyledImgContainer } from './styles';
import { LoopIcon } from '../../../assets/img';
import { TextView } from '../TextView';
import theme from '../../../theme';
import { LoginAnimation } from '../../../assets/img';

const LoginPanel: React.FunctionComponent = () => {
    return (
        <StyledContainer>
            <StyledParaContainer>
                <StyledLogo src={LoopIcon} alt={'Loop Health Logo'} />
                <StyledImgContainer>
                    <Lottie animationData={LoginAnimation} loop={true} />
                </StyledImgContainer>

                <TextView fontSize="40px" lineHeight={'48px'} margin="0px 0px 20px 0px" color={theme.colors.white}>
                    Streamline your <br />
                    <TextView fontSize="38px" margin="5px 0px 20px 10px" color={theme.colors.coverUpGreen}>
                        Claims process
                    </TextView>
                </TextView>

                <TextView
                    fontSize="18px"
                    margin="0px 0px 20px 10px"
                    color={theme.colors.white}
                    textAlign={'center'}
                    lineHeight={'24px'}
                >
                    Stay in Control with our All-Inclusive Dashboard for <br /> Tracking, Managing and Analysing Claims.
                </TextView>
            </StyledParaContainer>
        </StyledContainer>
    );
};

export default LoginPanel;
