import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchAgentsRoleAPI, fetchUsersByEmailOrMobileAPI } from '../../../adapters';

const USERDATA_ACTION_TYPES = {
    USERDATA_BY_PHONE_MOBILE: 'USERDATA_BY_PHONE_MOBILE',
    USERDATA_AGENT_ROLE: 'USERDATA_AGENT_ROLE'
};
interface UserPhoneEmailType {
    attribute: string;
    value: string;
}
export const fetchUserDetail = createAsyncThunk(
    USERDATA_ACTION_TYPES.USERDATA_BY_PHONE_MOBILE,
    async (data: UserPhoneEmailType) => {
        const res = await fetchUsersByEmailOrMobileAPI(data.attribute, data.value);
        return res;
    }
);

export const fetchUserRole = createAsyncThunk(USERDATA_ACTION_TYPES.USERDATA_AGENT_ROLE, async () => {
    try {
        const response = await fetchAgentsRoleAPI();
        return {
            userPermissions: response
        };
    } catch (e) {
        throw e;
    }
});
