import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchAllTpaAPI } from '../../../adapters';

const TPASLICE_ACTION_TYPES = {
    FETCH_TPA_LIST: 'FETCH_TPA_LIST'
};

export const fetchTpaList = createAsyncThunk(
    TPASLICE_ACTION_TYPES.FETCH_TPA_LIST,
    async () => {
        const res = await fetchAllTpaAPI();
        return res?.data?.tpa;
    }
);
