import styled from 'styled-components';

export const StyledOverviewWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0px 10px 0px 30px;
    margin-top: 30px;
    width: 62%;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
`;

export const StyledStatusWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 38%;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    background-color: ${(p) => p.theme.colors.white};
    padding: 40px 30px;
`;

export const StyledWrapper = styled.div<{ $width: string; $align?: string }>(
    (p) => `
    width: ${p.$width ? p.$width : `100%`};
    display:flex;
    align-items: ${p.$align ? p.$align : `flex-start`};
    `
);

export const StyledActionWrapper = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
`;

export const StyledActionBTN = styled.button`
    padding: 15px;
    border-radius: 10px;
    max-width: 132px;
    height: 46px;
    border: none;
    display: flex;
    align-items: center;
    column-gap: 10px;
    color: ${(p) => p.theme.colors.loopEmerald};
    background-color: ${(p) => p.theme.colors.coverUpGreen};
    cursor: pointer;
    width: 100%;
    justify-content: center;
    font-family: 'Work Sans';
    font-size: 14px;
`;

export const StyledActionContainer = styled.ul`
    min-width: 220px;
    min-height: 90px;
    display: flex;
    position: absolute;
    left: -142px;
    top: 40px;
    padding: 0;
    z-index: 1;
    border: 0;
    align-items: center;
    background-color: ${(p) => p.theme.colors.white};
    cursor: pointer;
    display: flex;
    flex-direction: column;
`;

export const StyledActionLI = styled.li`
    list-style: none;
    padding: 15px 20px;
    width: 100%;
    display: flex;
    font-style: normal;
    font-weight: 400;
    justify-content: flex-start;
    background-color: ${(p) => p.theme.colors.white};
    color: ${(p) => p.theme.colors.beyondGrey};
    letter-spacing: 0.2px;
    font-size: ${(p) => p.theme.fontSizes.mediumFont};
    box-shadow: 0px -1px 3px 0px rgb(0 0 0 / 8%);
    justify-content: flex-start;
    &:hover {
        cursor: pointer;
        background-color: ${(p) => p.theme.colors.mutedBG} !important;
    }
`;

export const StyledActions = styled.div`
    display: flex;
    position: relative;
`;

export const StyledActionIcon = styled.img<{
    $rotate: boolean;
}>`
    transform: ${(p) => (p.$rotate ? 'rotate(90deg)' : 'rotate(270deg)')};
    cursor: pointer;
`;

export const StyledTabsContainer = styled.div<{
    $margin?: string;
}>`
    display: flex;
    width: 100%;
    margin-bottom: 14px;
    margin-top: ${(p) => p.$margin ?? '0px'};
    border-bottom: 1px solid ${(p) => p.theme.colors.lightGreen};
`;

export const StyledCoveredTab = styled.div<{
    $active: boolean;
    $width?: string;
}>`
    padding-bottom: 13px;
    width: ${(p) => p.$width ?? '25%;'};
    cursor: pointer;
`;

export const StyledNotCoveredTab = styled.div<{
    $active: boolean;
}>`
    padding-bottom: 13px;
    width: 25%;
    cursor: pointer;
    border-radius: 3px;
`;

export const StyledNotCoveredTabTitle = styled.span<{
    $active: boolean;
}>`
    color: ${(p) => (p.$active ? p.theme.colors.beyondGrey : p.theme.colors.loopEmerald)};
    font-weight: 500;
    font-size: ${(p) => p.theme.fontSizes.body};
`;

export const StyledTabWrapper = styled.div`
    display: flex;
    align-items: center;
    padding-bottom: 10px;
`;

export const StyledFreshdeskWrapper = styled.div`
    display: flex;
    width: 100%;
    height: 100px;
    background: blue;
`;

export const StyledModalWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 20px;
`;

export const StyledSendIcon = styled.img<{
    $width?: string;
    $margin?: string;
}>`
    max-height: ${(p) => p.$width ?? '24px'};
    margin-left: ${(p) => p.$margin ?? '10px'};
`;

export const StyledRemarksIcon = styled.img<{
    $rotate: boolean;
}>`
    transform: ${(p) => (p.$rotate ? 'rotate(0deg)' : '')};
    cursor: pointer;
`;

export const StyledTypoWrapperMargin = styled.div<{
    $margin: string;
}>`
    display: flex;
    margin: ${(p) => p.$margin};
`;

export const StyledButtonWrapper = styled.div`
    display: flex;
    width: 100%;
    gap: 24px;
`;
