/* eslint-disable camelcase */
import React from 'react';
interface ISegmentValues {
    userId: string;
    login_method_type?: string;
    login_method?: string;
    commonMeta?: Record<string, unknown>;
}
const initialState = {
    userId: '',
    companyId: '',
    login_method_type: '',
    login_method: ''
} as ISegmentValues;

const SegmentContext = React.createContext<ISegmentValues>(initialState);

type ISegmentType = 'page' | 'click' | 'identify' | 'task';
const useSegment = (
    type: ISegmentType,
    commonProperties?: Record<string, unknown>
): (({ name, properties }: { name: string; properties?: Record<string, unknown> }) => void) => {
    const userAgent = window.navigator.userAgent;
    const { userId, login_method_type, login_method, commonMeta = {} } = React.useContext<ISegmentValues>(
        SegmentContext
    );
    if (type === 'page') {
        return ({ name, properties }: { name: string; properties?: Record<string, unknown> }) => {
            window.analytics.track(
                {
                    event: 'Page',
                    type,
                    properties: {
                        ...properties,
                        ...commonProperties,
                        page_title: name,
                        form_name: name,
                        platform: 'nucleus',
                        device_id: userAgent,
                        user_id: userId,
                        login_method_type,
                        login_method
                    }
                },
                {
                    traits: commonMeta
                }
            );
        };
    } else if (type === 'click') {
        return ({ name, properties }: { name: string; properties?: Record<string, unknown> }) =>
            window.analytics.track(
                {
                    event: 'ClickedOn',
                    type,
                    properties: {
                        ...properties,
                        ...commonProperties,
                        user_id: userId,
                        clicked_on: name,
                        form_name: name,
                        platform: 'nucleus',
                        device_id: userAgent,
                        login_method_type,
                        login_method
                    }
                },
                {
                    traits: commonMeta
                }
            );
    } else if (type === 'identify') {
        return ({ name }: { name: string }) =>
            window.analytics.identify(
                userId,
                {
                    ...commonMeta,
                    platform: 'nucleus',
                    event: 'IdentifyUser',
                    userId: userId,
                    name: name,
                    login_method_type,
                    login_method
                },
                {
                    traits: commonMeta,
                    platform: 'nucleus',
                    event: 'IdentifyUser',
                    userId: userId,
                    name: name,
                    login_method_type,
                    login_method
                }
            );
    } else if (type === 'task') {
        return ({ name, properties }) =>
            window.analytics.track(
                {
                    event: name,
                    type,
                    properties: {
                        ...properties,
                        ...commonProperties,
                        login_method_type,
                        login_method
                    }
                },
                {
                    traits: commonMeta
                }
            );
    }
    return () => null;
};

export const SegmentProvider = SegmentContext.Provider;
export type { ISegmentValues };
export default useSegment;
