export const filters = [
    {
        name: 'Insurer name',
        type: 'multiselect',
        key: 'insurerName',
        position: 'sidebar',
        values: [
            {
                id: 'Aditya Birla Health Insurance Co. Ltd.',
                label: 'Aditya Birla Health Insurance Co. Ltd.'
            },
            {
                id: 'IFFCO Tokio General Insurance Co. Ltd.',
                label: 'IFFCO Tokio General Insurance Co. Ltd.'
            },
            {
                id: 'The New India Assurance Company Limited',
                label: 'The New India Assurance Company Limited'
            },
            {
                id: 'ICICI Lombard General Insurance Co. Ltd',
                label: 'ICICI Lombard General Insurance Co. Ltd'
            },
            {
                id: 'Magma HDI General Insurance Co. Ltd.',
                label: 'Magma HDI General Insurance Co. Ltd.'
            },
            {
                id: 'Niva Bupa Health Insurance Co Ltd.',
                label: 'Niva Bupa Health Insurance Co Ltd.'
            },
            {
                id: 'National Insurance Co. Ltd.',
                label: 'National Insurance Co. Ltd.'
            },
            {
                id: 'SBI General Insurance Co. Ltd.',
                label: 'SBI General Insurance Co. Ltd.'
            },
            {
                id: 'Tata AIG General Insurance Co. Ltd.',
                label: 'Tata AIG General Insurance Co. Ltd.'
            },
            {
                id: 'ManipalCigna Health Insurance Company Ltd',
                label: 'ManipalCigna Health Insurance Company Ltd'
            },
            {
                id: 'The Oriental Insurance Co. Ltd.',
                label: 'The Oriental Insurance Co. Ltd.'
            },
            {
                id: 'Care Health Insurance Ltd',
                label: 'Care Health Insurance Ltd'
            },
            {
                id: 'THE NEW INDIA ASSURANCE COMPANY LIMITED',
                label: 'THE NEW INDIA ASSURANCE COMPANY LIMITED'
            },
            {
                id: 'Navi Bupa',
                label: 'Navi Bupa'
            },
            {
                id: 'Go Digit',
                label: 'Go Digit'
            },
            {
                id: 'Cholamandalam',
                label: 'Cholamandalam'
            },
            {
                id: 'Star Health',
                label: 'Star Health'
            },
            {
                id: 'Raheja QBE General Insurance Co. Ltd.',
                label: 'Raheja QBE General Insurance Co. Ltd.'
            }
        ]
    }
];
