import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './initialState';
import { fetchPolicyById } from './thunks';

const policySlice = createSlice({
    name: 'policySlice',
    initialState,
    reducers: {
        // userDetailByMobileEmail(state,{payload}){
        //     state.policyDetail.data=state.policyDetails.data
        // },
        resetPolicyDataState(state) {
            state.policyDetail = initialState.policyDetail;
        }
    },
    extraReducers: {
        [fetchPolicyById.pending as any]: (state) => {
            state.policyDetail.loading = true;
        },
        [fetchPolicyById.rejected as any]: (state, { error }) => {
            state.policyDetail.error = error;
            state.policyDetail.loading = false;
            state.policyDetail.data = null;
        },
        [fetchPolicyById.fulfilled as any]: (state, { payload }) => {
            state.policyDetail.error = null;
            state.policyDetail.loading = false;
            state.policyDetail.data = payload;
        },
        'common/cleanup': (state) => {
            state.policyDetail = initialState.policyDetail;
        }
    }
});
export default policySlice.reducer;
export const { resetPolicyDataState } = policySlice.actions;
export { fetchPolicyById };
