import React from 'react';
import { createPortal } from 'react-dom';
import { StyledNavOverlay } from './styles';
import { IFloatingNavigation } from './types';

const FloatingNavigation: React.FunctionComponent<IFloatingNavigation> = ({
    children,
    isVisible,
    startLeaveAnimation
}) => {
    const navRef = React.useRef<HTMLDivElement | null>(null);
    React.useEffect(() => {
        if (navRef.current) {
            const rootElem = document.createElement('div');
            rootElem.setAttribute('id', 'floating-menu-loop-health');
            if (document.body.lastElementChild) {
                document.body.insertBefore(rootElem, document.body.lastElementChild.nextElementSibling);
            }
            rootElem.appendChild(navRef.current);
        }
    }, [navRef.current]);
    React.useEffect(() => {
        if (!navRef.current) {
            navRef.current = document.createElement('div');
        }
    }, []);
    return isVisible && navRef.current ? (
        createPortal(
            <StyledNavOverlay $startLeaveAnimation={startLeaveAnimation}>{children}</StyledNavOverlay>,
            navRef.current
        )
    ) : (
        <></>
    );
};

export default FloatingNavigation;
