import styled from 'styled-components';

export const StyledModalContainer = styled.div`
    width: 100%;
    min-width: 850px;
    max-width: 1200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-height: 500px;
`;

export const StyledButtonContainer = styled.div`
    display: flex;
`;

export const StyledButtonWrapper = styled.div`
    display: flex;
    width: 100%;
    justify-content: flex-end;
`;

export const StyledDetailsWrapper = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    margin-bottom: 20px;
`;

export const StyledDetailSection = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    background: ${(p) => p.theme.colors.secondaryBG};
    padding: 16px 36px;
    margin-right: 24px;
    border-radius: 6px;
`;

export const StyledContentWrapper = styled.div`
    display: flex;
    align-items: center;
    background: ${(p) => p.theme.colors.secondaryBG};
    flex-direction: column;
    padding: 30px;
    width: 100%;
`;

export const StyledSelectionWrapper = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
`;
export const StyledSelectionSection = styled.div`
    display: flex;
`;
export const StyledSelectionTab = styled.div<{
    $selected: boolean;
}>`
    display: flex;
    margin-right: 14px;
    color: ${(p) => (p.$selected ? p.theme.colors.loopEmerald : p.theme.colors.borderLine)};
    font-weight: ${(p) => (p.$selected ? 600 : 300)};
    cursor: pointer;
`;
export const StyledSortSection = styled.div`
    display: flex;
    min-width: 17%;
    align-items: center;
`;

export const StyledInfoTAB = styled.div(
    (p) => `
    display: flex;
    font-size: ${p.theme.fontSizes.body};
    background-color: ${p.theme.colors.white};
    color: ${p.theme.colors.beyondGrey};
    padding:11px 20px;
    font-wight:500;
    cursor:pointer;
    border: 1px solid ${p.theme.colors.borderLine};
    border-radius: 8px;
  `
);

export const StyledSortIcon = styled.img<{
    $width?: string;
    $margin?: string;
}>`
    max-height: ${(p) => p.$width ?? '24px'};
    margin-left: ${(p) => p.$margin ?? '10px'};
`;

export const StyledSelectionContainer = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    margin-top: 20px;
`;
export const StyledContainer = styled.div`
    display: flex;
    margin-bottom: 50px;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    column-gap: 16px;
    row-gap: 16px;
    @media (max-width: 768px) {
        flex-direction: column;
        align-items: initial;
    }
`;
export const StyledAssignmentWrapper = styled.div`
    display: flex;
    width: 96%;
    padding: 30px;
    position: sticky;
    bottom: 0;
    justify-content: space-between;
    align-items: center;
    background-color: ${(p) => p.theme.colors.white};
`;

export const StyledRowContainer = styled.div`
    display: flex;
    margin-left: 20px;
`;

export const StyledWrapper = styled.div`
    display: flex;
    align-items: center;
`;

export const StyledModalWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 20px;
    text-align: center;
`;

export const StyledSendIcon = styled.img<{
    $width?: string;
    $margin?: string;
}>`
    max-height: ${(p) => p.$width ?? '24px'};
    margin-left: ${(p) => p.$margin ?? '10px'};
`;

export const StyledTypoWrapperMargin = styled.div<{
    $margin: string;
}>`
    display: flex;
    margin: ${(p) => p.$margin};
`;

export const StyledAssignModalWrapper = styled.div<{
    $display: boolean;
}>`
    margin: ${(p) => (p.$display ? 'flex' : 'none')};
`;

export const StyledContainerSection = styled.div`
    display: flex;
    margin: 10px 0px;
    padding: 12px 20px;
    justify-content: space-between;
    align-items: flex-start;
    border-radius: 8px;
    background: ${(p) => p.theme.colors.secondaryBG};
`;
