import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchPolicyAPI } from '../../../adapters';

const POLICYSLICE_ACTION_TYPES = {
    FETCH_COMPANY_POLICY: 'FETCH_COMPANY_POLICY'
};

export const fetchPolicyById = createAsyncThunk(
    POLICYSLICE_ACTION_TYPES.FETCH_COMPANY_POLICY,
    async (policyId: string) => {
        const res = await fetchPolicyAPI(policyId);
        return res.data;
    }
);
