import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ReduxState } from '../../../../../redux';
import { Loader } from '../../../../atoms';
import { EXTERNAL_ROUTES } from '../../../../../utils/constants/Routes';
import {
    ActiveBookMarkIcon,
    ActiveEditIcon,
    ActiveFreshDeskIcon,
    ActiveStatisticsIcon,
    BookMarkIcon,
    DownArrowIcon,
    EditIcon,
    FreshDeskIcon,
    StatisticsIcon,
    TickIcon
} from '../../../../../assets/img';
import { useDispatch } from 'react-redux';
import { resetState } from '../../../../../redux/slices/TpaDataSlice';
import { resetClaimsDetailsState } from '../../../../../redux/slices/ClaimDetailsSlice';
import { fetchClaimDetailsById } from '../../../../../redux/slices/ClaimDetailsSlice';
import { fetchClaimRemarks } from '../../../../../redux/slices/ClaimDetailsSlice/thunks';
import { addClaimRemarkById } from '../../../../../redux/slices/ClaimDetailsSlice/thunks';

import { ClaimPriority } from '../../../../../utils/constants';
import {
    StyledWrapper,
    StyledOverviewWrapper,
    StyledStatusWrapper,
    StyledActionWrapper,
    StyledActionBTN,
    StyledActionIcon,
    StyledActions,
    StyledActionContainer,
    StyledActionLI,
    StyledTabsContainer,
    StyledCoveredTab,
    StyledNotCoveredTab,
    StyledTabWrapper,
    StyledModalWrapper,
    StyledSendIcon,
    StyledRemarksIcon,
    StyledTypoWrapperMargin,
    StyledButtonWrapper
} from './styles';
import { TextView } from '../../../../atoms/TextView';
import theme from '../../../../../theme';
import { useToast } from '../../../../../utils/hooks/useToast';
import { updateClaimPriority, assignClaim } from '../../../../../adapters';
import { useConfirmationModalContext } from '../../../../../utils/hooks/modalConfirmationContext';
import { LoopButton, LoopTag, Typography } from '@loophealth/loop-ui-web-library';
import { displayToastNotification } from '../../../../../utils/common/common';
import ClaimDetailsModal from '../../../../containers/ClaimDetailsModal';
const RemarksSection = React.lazy(() => import('../../../../containers/RemarksSection'));
const ClaimStatusSection = React.lazy(() => import('../../../../containers/ClaimStatusSection'));
const ClaimOverviewSection = React.lazy(() => import('../../../../containers/ClaimOverviewSection'));
const Freshdesk = React.lazy(() => import('../../../../containers/Freshdesk'));
const CompanyDetails: React.FunctionComponent = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const toast = useToast();
    const ActionRef = React.useRef<HTMLDivElement | null>(null);
    const { claimDetails, remarks, addRemarkState, claimPriorityState } = useSelector(
        (state: ReduxState) => state.ClaimDetailsSlice
    );
    const [claimDetailsIsVisible, setClaimDetailsIsVisible] = React.useState<boolean>(false);
    const selectedClaim = claimDetails.data;
    const [searchParams] = useSearchParams();
    const id = searchParams.get('id');
    const [actionBtnVisible, setActionBtnVisible] = React.useState(false);

    React.useEffect(() => {
        const checkIfClickedOutside = (e: { target: any }) => {
            if (actionBtnVisible && ActionRef?.current && !ActionRef?.current?.contains(e.target)) {
                setActionBtnVisible(false);
            }
        };
        document.addEventListener('mousedown', checkIfClickedOutside);
        return () => {
            document.removeEventListener('mousedown', checkIfClickedOutside);
        };
    }, [actionBtnVisible]);
    if (!claimDetails.loading && !claimDetails.data) {
        navigate(EXTERNAL_ROUTES.APP);
    }

    const initialFunction = (id: string) => {
        dispatch(resetClaimsDetailsState());
        dispatch(fetchClaimDetailsById(id));
        dispatch(fetchClaimRemarks(id));
        dispatch(resetState());
    };

    const addRemark = (id: string | null, remark: string) => {
        if (id && remark) {
            const data = {
                id: id,
                remark: remark
            };
            dispatch(addClaimRemarkById(data));
        }
    };

    React.useEffect(() => {
        if (id) {
            initialFunction(id);
        } else {
            navigate(EXTERNAL_ROUTES.APP);
        }
    }, [id]);

    React.useEffect(() => {
        if (id && !addRemarkState?.loading) {
            displayToastNotification(addRemarkState?.data, toast);
            dispatch(fetchClaimRemarks(id));
            setOverviewActiveTab(1);
        }
    }, [addRemarkState?.loading]);

    React.useEffect(() => {
        if (id) {
            dispatch(resetClaimsDetailsState());
            dispatch(fetchClaimDetailsById(id));
        }
    }, [claimPriorityState?.loading]);
    const [overviewActiveTab, setOverviewActiveTab] = React.useState<number>(1);
    const [statusActiveTab, setStatusActiveTab] = React.useState<number>(1);
    const showToasterMessage = (isError: boolean, message: string) => {
        if (toast && toast.displayToast) {
            toast.displayToast(isError ? 'error' : 'success', isError ? 'Something went wrong' : `${message}.`, '');
        }
    };
    const modalContext = useConfirmationModalContext();
    const markClaimVIP = async (id: string) => {
        const isConfirmed = await modalContext.showConfirmation({
            title: '',
            message: (
                <StyledModalWrapper>
                    <StyledSendIcon $width="72px" $margin="0px" src={TickIcon} />
                    <StyledTypoWrapperMargin $margin="20px 0px 10px 0px">
                        <Typography variant="large" weight="bold">
                            Are you sure?
                        </Typography>
                    </StyledTypoWrapperMargin>

                    <Typography variant="small">
                        This will mark claim <b>{selectedClaim?.tpaClaimId}</b> as VIP.
                    </Typography>
                </StyledModalWrapper>
            ),
            yesLabel: 'Mark VIP',
            noLabel: 'Cancel'
        });
        if (isConfirmed) {
            const result = await updateClaimPriority(id, ClaimPriority.VIP);
            const res = result?.calimId ? true : false;
            if (res) {
                showToasterMessage(false, 'Claim successfully mark as VIP');
            } else {
                showToasterMessage(true, '');
            }
        }
    };
    const assignClaimToL2 = async (claimId: string) => {
        const isConfirmed = await modalContext.showConfirmation({
            title: '',
            message: (
                <StyledModalWrapper>
                    <StyledSendIcon $width="72px" $margin="0px" src={TickIcon} />
                    <StyledTypoWrapperMargin $margin="20px 0px 10px 0px">
                        <Typography variant="large" weight="bold">
                            Are you sure?
                        </Typography>
                    </StyledTypoWrapperMargin>

                    <Typography variant="small">
                        This will assign claim <b>{selectedClaim?.tpaClaimId}</b> to L2 professional.
                    </Typography>
                </StyledModalWrapper>
            ),
            yesLabel: 'Assign',
            noLabel: 'Cancel'
        });
        if (isConfirmed) {
            const result = await assignClaim(claimId);
            const res = result?.calimId ? true : false;
            if (res) {
                showToasterMessage(false, 'Claim successfully assign to L2');
            } else {
                showToasterMessage(true, '');
            }
        }
    };

    const handleClaimDetailsClicked = () => {
        setClaimDetailsIsVisible(true);
    };

    return (
        (selectedClaim && (
            <StyledWrapper $width="100%">
                <StyledOverviewWrapper>
                    <StyledActionWrapper>
                        <StyledWrapper $width="100%" $align="center">
                            <StyledRemarksIcon src={DownArrowIcon} onClick={() => navigate(-1)} $rotate={true} />
                            <TextView
                                fontSize="16px"
                                color={theme.colors.beyondGrey}
                                lineHeight="24px"
                                fontWeight="500"
                                margin="0px 10px 0px 8px"
                            >
                                Claim <b>{selectedClaim?.tpaClaimId ?? ''}</b>
                            </TextView>
                            {selectedClaim?.priority && selectedClaim.priority === ClaimPriority.VIP && (
                                <LoopTag variant="filled">VIP</LoopTag>
                            )}
                        </StyledWrapper>
                        <StyledButtonWrapper>
                            <LoopButton variant="secondary" onClick={handleClaimDetailsClicked}>
                                View Claim Details from TPA
                            </LoopButton>
                            <StyledActions ref={ActionRef}>
                                <StyledActionBTN
                                    onClick={() => {
                                        setActionBtnVisible(!actionBtnVisible);
                                    }}
                                >
                                    Actions
                                    <StyledActionIcon src={DownArrowIcon} $rotate={actionBtnVisible} />
                                </StyledActionBTN>
                                {actionBtnVisible ? (
                                    <StyledActionContainer>
                                        <StyledActionLI
                                            onClick={() => {
                                                setActionBtnVisible(false);
                                                assignClaimToL2(selectedClaim?.id);
                                            }}
                                        >
                                            Assign Claim to L2
                                        </StyledActionLI>
                                        <StyledActionLI
                                            onClick={() => {
                                                setActionBtnVisible(false);
                                                markClaimVIP(selectedClaim?.id);
                                            }}
                                        >
                                            Mark Claim As VIP
                                        </StyledActionLI>
                                    </StyledActionContainer>
                                ) : null}
                            </StyledActions>
                        </StyledButtonWrapper>
                    </StyledActionWrapper>
                    <StyledTabsContainer $margin="50px">
                        <StyledCoveredTab $active={overviewActiveTab === 1} onClick={() => setOverviewActiveTab(1)}>
                            <StyledTabWrapper>
                                <StyledRemarksIcon
                                    src={overviewActiveTab === 1 ? ActiveBookMarkIcon : BookMarkIcon}
                                    $rotate={false}
                                />
                                <TextView
                                    fontSize="16px"
                                    margin="0px 0px 0px 10px"
                                    fontWeight="500"
                                    color={overviewActiveTab === 1 ? theme.colors.loopEmerald : theme.colors.beyondGrey}
                                >
                                    Claims Overview
                                </TextView>
                            </StyledTabWrapper>
                        </StyledCoveredTab>
                        <StyledNotCoveredTab $active={overviewActiveTab === 2} onClick={() => setOverviewActiveTab(2)}>
                            <StyledTabWrapper>
                                <StyledRemarksIcon
                                    src={overviewActiveTab === 2 ? ActiveFreshDeskIcon : FreshDeskIcon}
                                    $rotate={false}
                                />
                                <TextView
                                    fontSize="16px"
                                    margin="0px 0px 0px 10px"
                                    fontWeight="500"
                                    lineHeight="24px"
                                    color={overviewActiveTab === 2 ? theme.colors.loopEmerald : theme.colors.beyondGrey}
                                >
                                    Freshdesk Timeline
                                </TextView>
                            </StyledTabWrapper>
                        </StyledNotCoveredTab>
                    </StyledTabsContainer>
                    {overviewActiveTab === 1 ? (
                        <>
                            <ClaimOverviewSection claimDetails={selectedClaim} />
                        </>
                    ) : (
                        <Freshdesk />
                    )}
                </StyledOverviewWrapper>
                <StyledStatusWrapper>
                    <StyledTabsContainer>
                        <StyledCoveredTab
                            $width="35%"
                            $active={statusActiveTab === 1}
                            onClick={() => setStatusActiveTab(1)}
                        >
                            <StyledTabWrapper>
                                <StyledRemarksIcon
                                    src={statusActiveTab === 1 ? ActiveStatisticsIcon : StatisticsIcon}
                                    $rotate={false}
                                />
                                <TextView
                                    fontSize="16px"
                                    margin="0px 0px 0px 10px"
                                    fontWeight="500"
                                    color={statusActiveTab === 1 ? theme.colors.loopEmerald : theme.colors.beyondGrey}
                                >
                                    Claims Status
                                </TextView>
                            </StyledTabWrapper>
                        </StyledCoveredTab>
                        <StyledNotCoveredTab $active={statusActiveTab === 2} onClick={() => setStatusActiveTab(2)}>
                            <StyledTabWrapper>
                                <StyledRemarksIcon
                                    src={statusActiveTab === 2 ? ActiveEditIcon : EditIcon}
                                    $rotate={false}
                                />
                                <TextView
                                    fontSize="16px"
                                    margin="0px 0px 0px 10px"
                                    fontWeight="500"
                                    lineHeight="24px"
                                    color={statusActiveTab === 2 ? theme.colors.loopEmerald : theme.colors.beyondGrey}
                                >
                                    Remarks
                                </TextView>
                            </StyledTabWrapper>
                        </StyledNotCoveredTab>
                    </StyledTabsContainer>
                    {statusActiveTab === 1 ? (
                        <ClaimStatusSection data={[]} />
                    ) : (
                        <RemarksSection remarksData={remarks} addRemark={addRemark} addClaimState={addRemarkState} />
                    )}
                </StyledStatusWrapper>
                <ClaimDetailsModal isVisible={claimDetailsIsVisible} setIsVisible={setClaimDetailsIsVisible} />
            </StyledWrapper>
        )) || <Loader />
    );
};

export default CompanyDetails;
