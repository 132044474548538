import React from 'react';
import { capitalizeFirstLetter, capitalizeFirstLetterForEveryWord } from './common';

import { ActionsIcon } from '../../assets/img';
import { ClaimPriority } from '../constants';
import theme from '../../theme';
import { TextView } from '../../components/atoms/TextView';
import styled from 'styled-components';
import { Form, LoopTag } from '@loophealth/loop-ui-web-library';
import { ArrowRight, StyledMargin, StyledClaimSource, StyledActionContainer, StyledActionLI } from './styles';
import { getClaimAge, getFormattedDate } from '../services/dateService';

interface IStatusRow {
    id: string;
    value: string;
    name: string;
}
const caseInsensitiveSort = (rowA: any, rowB: any) => {
    const a = rowA.claimReceivedAt.toLowerCase();
    const b = rowB.claimReceivedAt.toLowerCase();
    if (a > b) {
        return 1;
    }

    if (b > a) {
        return -1;
    }
    return 0;
};

export const getDataTableColumns = (
    navigateToClaim: (id: string) => void,
    handleStatusChange: (id: string, status: string) => void,
    markClaimVIP: (id: string) => void,
    assignClaimToL2: (id: string) => void,
    handleClaimDetailsClicked: (id: string) => void,
    claimStatusOptions: IStatusRow[]
): any => {
    const commonProps = {
        fontSize: theme.fontSizes.label,
        color: theme.colors.beyondGrey
    };
    const StyledWrapper = styled.div`
        display: flex;
        width: 100%;
        align-items: center;
    `;

    const StyledTextWrapper = styled.div`
        flex: 1;
    `;

    return [
        {
            name: 'CLAIM ID',
            fixed: 'left',
            width: '210px',
            selector: function select(row: any) {
                return (
                    <StyledWrapper>
                        <StyledTextWrapper
                            onClick={() => {
                                navigateToClaim(row?.id);
                            }}
                        >
                            <TextView
                                margin="0px 5px 0px 0px"
                                fontSize={theme.fontSizes.label}
                                color={!row?.assignTo ? theme.colors.brownishYellow : theme.colors.beyondGrey}
                            >
                                {row?.tpaClaimId ?? '--'}
                            </TextView>
                        </StyledTextWrapper>
                        {row?.priority && row.priority === ClaimPriority.VIP && <LoopTag variant="filled">VIP</LoopTag>}
                    </StyledWrapper>
                );
            }
        },
        {
            name: 'CLAIM AGE',
            width: '120px',
            grow: 'no',
            selector: function select(row: any) {
                const age = getClaimAge(row?.claimReceivedAt);
                return age >= 10 && row.statusName != 'settled' ? (
                    <TextView fontSize={theme.fontSizes.label} color={theme.colors.planRed}>
                        {age} Days
                    </TextView>
                ) : (
                    `${age} Days`
                );
            },
            sortable: true,
            sortFunction: caseInsensitiveSort
        },
        {
            name: 'EMPLOYEE NAME',
            width: '160px',
            selector: function select(row: any) {
                return (
                    <>
                        <TextView {...commonProps}>{row?.employeeName ?? '--'}</TextView>
                    </>
                );
            }
        },
        {
            name: 'ACTION TAKEN',
            selector: (row: any) => row.statusName,

            width: '240px',
            grow: 'no',
            cell: function actionButton(row: any) {
                const [claimStatus, setClaimStatus] = React.useState(row?.currentStatus);
                const handleOptionChange = (id: string, value: string) => {
                    setClaimStatus(value);
                    handleStatusChange(id, value);
                };
                return (
                    <Form.Dropdown
                        selectedItem={claimStatus}
                        placeholder="Select Status"
                        onClick={(value: string) => handleOptionChange(row.id, value)}
                        listItems={claimStatusOptions}
                    />
                );
            }
        },
        {
            name: 'TPA STATUS',
            width: '150px',
            selector: function select(row: any) {
                return <TextView {...commonProps}>{capitalizeFirstLetterForEveryWord(row?.tpaStatus ?? '')}</TextView>;
            }
        },
        {
            name: 'CLAIM REC DATE',
            selector: function select(row: any) {
                return (
                    <>
                        <TextView {...commonProps}>{getFormattedDate(row.claimReceivedAt)}</TextView>
                    </>
                );
            },
            width: '154px',
            grow: 'no'
        },
        {
            name: 'EMPLOYEE ID',
            selector: function select(row: any) {
                return (
                    <>
                        <TextView {...commonProps}>{row?.employeeCode ?? ''}</TextView>
                    </>
                );
            },
            width: '125px',
            grow: 'no'
        },
        {
            name: 'COMPANY NAME',
            width: '160px',
            selector: function select(row: any) {
                return (
                    <>
                        <TextView {...commonProps}>{capitalizeFirstLetterForEveryWord(row?.company ?? '')}</TextView>
                    </>
                );
            }
        },
        {
            name: 'CLAIM TYPE',
            selector: function select(row: any) {
                return (
                    <>
                        <TextView {...commonProps}>{capitalizeFirstLetter(row?.type ?? '')}</TextView>
                    </>
                );
            },
            width: '140px',
            grow: 'no'
        },
        {
            name: 'ASSIGNED TO',
            selector: function select(row: any) {
                return (
                    <>
                        <TextView {...commonProps}>{capitalizeFirstLetter(row?.assignee?.name ?? '')}</TextView>
                    </>
                );
            },
            width: '150px',
            grow: 'no'
        },
        {
            name: 'Source',
            width: '120px',
            grow: 'no',
            cell: function actionButton(row: any) {
                return (
                    <StyledClaimSource $isClaimSourceTPA={row?.source != 'chatbot'}>
                        {row?.source != 'chatbot' ? 'TPA' : 'Chatbot'}
                    </StyledClaimSource>
                );
            }
        },
        {
            name: 'Action',
            cell: function actionButton(row: any) {
                const [actionBtnVisible, setActionBtnVisible] = React.useState(false);

                return (
                    <div className="fixed_column">
                        <StyledMargin
                            onClick={() => {
                                setActionBtnVisible(!actionBtnVisible);
                            }}
                        >
                            <img src={ActionsIcon} />
                        </StyledMargin>
                        {actionBtnVisible ? (
                            <>
                                <StyledActionContainer>
                                    <StyledActionLI
                                        onClick={() => {
                                            setActionBtnVisible(false);
                                            handleClaimDetailsClicked(row?.id);
                                        }}
                                    >
                                        View claim details from TPA
                                    </StyledActionLI>
                                    <StyledActionLI
                                        onClick={() => {
                                            setActionBtnVisible(false);
                                            assignClaimToL2(row?.id);
                                        }}
                                    >
                                        Assign Claim to L2
                                    </StyledActionLI>
                                    <StyledActionLI
                                        onClick={() => {
                                            setActionBtnVisible(false);
                                            markClaimVIP(row?.id);
                                        }}
                                    >
                                        Mark Claim As VIP
                                    </StyledActionLI>
                                </StyledActionContainer>
                                <ArrowRight />
                            </>
                        ) : null}
                    </div>
                );
            }
        }
    ];
};
