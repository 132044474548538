import React from 'react';
import FloatingNavigation from '../FloatingNavigation';
import { NavigationItemsContainer, StyledContainer } from './styles';
import { INavigationPanel } from './types';

const NavigationPanel: React.FunctionComponent<INavigationPanel> = ({
    isVisible,
    setIsVisible,
    children,
    direction
}) => {
    const [startLeaveAnimation, setStartLeaveAnimation] = React.useState(false);
    return (
        <FloatingNavigation
            isVisible={isVisible}
            setIsVisible={setIsVisible}
            startLeaveAnimation={startLeaveAnimation}
            setStartLeaveAnimation={setStartLeaveAnimation}
        >
            <StyledContainer $startLeaveAnimation={startLeaveAnimation} $direction={direction ?? false}>
                <NavigationItemsContainer>{children}</NavigationItemsContainer>
            </StyledContainer>
        </FloatingNavigation>
    );
};

export default NavigationPanel;
