import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './initialState';
import { fetchClaims, createClaim } from './thunks';

const claimSlice = createSlice({
    name: 'claimSlice',
    initialState,
    reducers: {
        claimsList(state, {}) {
            const currentState = state.claimList.data;
            const filteredCompanies = currentState;
            state.claimList.data = filteredCompanies;
        },
        resetClaimsState(state) {
            state.claimList = initialState.claimList;
            state.companyList = initialState.companyList;
            state.employeeList = initialState.employeeList;
            state.claimAgentList = initialState.claimAgentList;
            state.createClaim = initialState.createClaim;
        }
    },
    extraReducers: {
        [fetchClaims.pending as any]: (state) => {
            state.claimList.loading = true;
        },
        [fetchClaims.rejected as any]: (state, { error }) => {
            state.claimList.error = error;
            state.claimList.loading = false;
            state.claimList.data = null;
            state.companyList.data = null;
            state.employeeList.data = null;
        },
        [fetchClaims.fulfilled as any]: (state, action) => {
            const {
                payload,
                meta: { arg }
            } = action;
            // const { newData, newMetaData } = getFireBasePaginatedData({
            //     pageSize: arg.pageSize,
            //     argNextPageId: arg.nextPageId,
            //     argPrevPageId: arg.prevPageId,
            //     data: payload,
            //     nextPrevSelector: 'id',
            //     prevPageNo: state?.claimList?.metaData?.pageNo ?? 0
            // });

            state.claimList.error = null;
            state.claimList.loading = false;
            state.claimList.data = payload;
            state.claimList.selectedFilters = arg;
            // (state.claimList.metaData = {
            //     ...newMetaData,
            //     ..._.omit(arg, ['pageNo', 'hasPrev', 'hasNext', 'nextPageId', 'prevPageId'])
            // });

            // if (!state.companyList.data || !state.employeeList.data) {
            //     const { companiesData, employeesData, claimAgentsData } = prepareCompaniesAndEmployeesData(payload);
            //     state.companyList.data = companiesData;
            //     state.employeeList.data = employeesData;
            //     state.claimAgentList.data = claimAgentsData;
            // }
        },
        [createClaim.pending as any]: (state) => {
            state.createClaim.loading = true;
        },
        [createClaim.rejected as any]: (state, { error }) => {
            state.createClaim.error = error;
            state.createClaim.loading = false;
            state.createClaim.data = null;
        },
        [createClaim.fulfilled as any]: (state, { payload }) => {
            state.createClaim.error = null;
            state.createClaim.loading = false;
            state.createClaim.data = payload;
        },
        [createClaim.pending as any]: (state) => {
            state.createClaim.loading = true;
        },
        [createClaim.rejected as any]: (state, { error }) => {
            state.createClaim.error = error;
            state.createClaim.loading = false;
            state.createClaim.data = null;
        },
        [createClaim.fulfilled as any]: (state, { payload }) => {
            state.createClaim.error = null;
            state.createClaim.loading = false;
            state.createClaim.data = payload;
        },
        'common/cleanup': (state) => {
            state.claimList = initialState.claimList;
            state.companyList = initialState.companyList;
        }
    }
});
export default claimSlice.reducer;
export const { resetClaimsState, claimsList } = claimSlice.actions;
export { fetchClaims };
