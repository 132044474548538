import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchAllCompaniesAPI } from '../../../adapters';

const SLICE = {
    FETCH_COMPANY: 'FETCH_COMPANY'
};

export const fetchAllCompanies = createAsyncThunk(
    SLICE.FETCH_COMPANY,
    async () => {
        const res = await fetchAllCompaniesAPI();
        return res.data;
    }
);
