import { IUserSlice } from './types';

export const initialState: IUserSlice = {
    UserByPhoneOrMobileDetails: {
        data: null,
        error: null,
        loading: false
    },
    userPermissions: {
        data: null,
        error: null,
        loading: false
    }
};
