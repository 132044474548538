import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './initialState';
import { fetchClaimsFilters } from './thunks';
import { prepareClaimsFilters } from './helper';
import { filters } from './constant';

const FilterSlice = createSlice({
    name: 'FilterSlice',
    initialState,
    reducers: {
        resetState(state) {
            state.filters = initialState.filters;
        }
    },
    extraReducers: {
        [fetchClaimsFilters.pending as any]: (state) => {
            state.filters.loading = true;
        },
        [fetchClaimsFilters.rejected as any]: (state, { error }) => {
            state.filters.error = error;
            state.filters.loading = false;
            state.filters.data = [];
        },
        [fetchClaimsFilters.fulfilled as any]: (state, { payload }) => {
            const filtersData = [...payload?.data?.filters, ...filters];
            const data = prepareClaimsFilters(filtersData);
            state.filters.data = data;
            state.filters.error = null;
            state.filters.loading = false;
        },
        'common/cleanup': (state) => {
            state.filters = initialState.filters;
        }
    }
});
export default FilterSlice.reducer;
export const { resetState } = FilterSlice.actions;
export { fetchClaimsFilters };
