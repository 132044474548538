import React from 'react';
import { Card, Loader, UploadPill } from '../../../../atoms';
import {
    StyledContainer,
    StyledSubTitle,
    StyledTitle,
    StyledWrapper,
    StyledCloseIcon,
    StyledTitleContainer
} from './styles';
import { useToast } from '../../../../../utils/hooks/useToast';
import { bulkUpload } from '../../../../../adapters';
import { excelDownload } from '../../../../../utils/common/common';
import { CloseIcon } from '../../../../../assets/img';
import { useNavigate } from 'react-router-dom';
import { EXTERNAL_ROUTES, INTERNAL_ROUTES } from '../../../../../utils/constants/Routes';

const UploadClaim: React.FunctionComponent = () => {
    const toast = useToast();
    const navigate = useNavigate();
    const [bulkCreateError, setbulkCreateError] = React.useState('');
    const [loader, setLoader] = React.useState<boolean>(false);
    const toBase64 = (file: any) =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    const uploadFile = async (files: Record<string, unknown>[]) => {
        setLoader(true);
        const base64String = await toBase64(files[0]);
        if (!base64String) {
            setbulkCreateError('Error in file upload');
            return;
        }
        const base64Data = (base64String as string).split(',')[1];
        const result = await bulkUpload({
            file: base64Data
        });
        setLoader(false);
        if (result.data?.id.length > 0) {
            const fileName = `Claims Errors-${new Date().toLocaleString()}`;
            excelDownload(result.data?.id, fileName);
        }
        if (toast && toast.displayToast) {
            const isError = result.data?.id?.length ? true : false;
            toast.displayToast(
                isError ? 'error' : 'success',
                isError ? 'Something went wrong' : 'Claims files successfully uploaded',
                ''
            );
        }
    };
    const navigateClaimDirectory = () => {
        navigate(EXTERNAL_ROUTES.APP + '/' + INTERNAL_ROUTES.CLAIM_DIRECTORY.route);
    };
    return (
        <StyledWrapper>
            <StyledContainer>
                <StyledTitleContainer>
                    <StyledTitle>Upload TPA MIS Document</StyledTitle>
                    <StyledCloseIcon src={CloseIcon} onClick={navigateClaimDirectory} />
                </StyledTitleContainer>

                <Card>
                    {loader ? (
                        <>
                            <StyledWrapper>
                                <StyledTitle>Please Wait</StyledTitle>
                            </StyledWrapper>
                            <Loader inverted />
                        </>
                    ) : (
                        <UploadPill
                            onUpload={uploadFile}
                            title={'Bulk Upload'}
                            subTitle="Supports (.csv)"
                            formats={['.csv']}
                            errorMsg={bulkCreateError}
                            maxSize={5}
                        ></UploadPill>
                    )}
                    <StyledSubTitle>Accepted files : .csv only</StyledSubTitle>
                </Card>
            </StyledContainer>
        </StyledWrapper>
    );
};

export default UploadClaim;
