import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './initialState';
import { fetchAllClaimAgents } from './thunks';
import { IClaimAgentSlice } from './types';

const claimAgentSlice = createSlice({
    name: 'claimAgentSlice',
    initialState,
    reducers: {
        resetClaimAgentsState(state) {
            state.claimAgentsData = initialState.claimAgentsData;
        }
    },
    extraReducers: {
        [fetchAllClaimAgents.pending as any]: (state: IClaimAgentSlice) => {
            state.claimAgentsData.loading = true;
            state.claimAgentsData.error = null;
            state.claimAgentsData.data = null;
        },
        [fetchAllClaimAgents.rejected as any]: (state: IClaimAgentSlice, { error }) => {
            state.claimAgentsData.error = error;
            state.claimAgentsData.loading = false;
            state.claimAgentsData.data = null;
        },
        [fetchAllClaimAgents.fulfilled as any]: (state: IClaimAgentSlice, { payload }) => {
            state.claimAgentsData.error = null;
            state.claimAgentsData.loading = false;
            state.claimAgentsData.data = payload.claimAgentsData;
        },
        'common/cleanup': (state) => {
            state.claimAgentsData = initialState.claimAgentsData;
        }
    }
});
export default claimAgentSlice.reducer;
export const { resetClaimAgentsState } = claimAgentSlice.actions;
export { fetchAllClaimAgents };
