import styled from 'styled-components';

export const StyledHeader = styled.header`
    padding: 0 30px;
    background: #fff;
`;

export const StyledFlex = styled.div< {
    gap?: string;
    direction?: 'row' | 'row-reverse' | 'column' | 'column-reverse';
    justifyContent?: 'flex-start' | 'flex-end' | 'center' | 'space-between' | 'space-around' | 'space-evenly';
    alignItems?: 'stretch' | 'flex-start' | 'flex-end' | 'center' | 'baseline';
    flexWrap?: 'nowrap' | 'wrap' | 'wrap-reverse';
    margin?: string
    padding?: string
}>`
  display: flex;
  gap: ${(p) => (p.gap ? p.gap + 'px' : '0px')};
  flex-direction: ${(p) => p.direction || 'row'};
  justify-content: ${(p) => p.justifyContent || 'flex-start'};
  align-items: ${(p) => p.alignItems || 'stretch'};
  flex-wrap: ${(p) => p.flexWrap || 'nowrap'};
  margin: ${(p) => (p.margin ? p.margin : '0px')};
  padding: ${(p) => (p.padding ? p.padding : '0px')};
`;

export const StyledTableContainer = styled.header`
    flex: 1;
    overflow-y: auto;
    padding-bottom: 12px;
`;

export const StyledInputContainer = styled.div`
    flex-shrink: 0;
    min-width: 540px;
`;
