/* eslint-disable new-cap, @typescript-eslint/explicit-module-boundary-types */
import { get, patch, post } from './provider';
import { IClaimStatusMapping, IResponse } from './types';

const ENDPOINTS = {
    CLAIM_STATUS_MAPPING: {
        CREATE: '/claims/status-mappings',
        FETCH_ALL: '/claims/status-mappings',
        UPDATE_UNMAPPED_BY_ID: (id: number) => `/claims/status-mappings/${id}`
    }
};

export const ClaimStatusMapping = {
    fetchAll: () => get<IClaimStatusMapping[]>(ENDPOINTS.CLAIM_STATUS_MAPPING.FETCH_ALL),
    create: (misStatus: string, dashboardStatusEnum: string) =>
        post<IResponse<unknown>>(ENDPOINTS.CLAIM_STATUS_MAPPING.CREATE, { misStatus, dashboardStatusEnum }),
    updateUnmappedById: (id: number, dashboardStatusEnum: string) =>
        patch<IResponse<unknown>>(ENDPOINTS.CLAIM_STATUS_MAPPING.UPDATE_UNMAPPED_BY_ID(id), { dashboardStatusEnum })
};
