import { combineReducers } from 'redux';
import { ApplicationStateSlice } from '../slices';
import {
    ClaimSlice,
    UserDataSlice,
    PolicySlice,
    TpaDataSlice,
    CompanySlice,
    ClaimAgentSlice,
    ClaimDetailsSlice,
    FiltersSlice,
    ClaimStatusOptionsSlice,
    TpaListSlice
} from '../slices';
import { UserSlice } from '../../components/pages/LoginContainer/redux/slices';
const rootReducer = combineReducers({
    applicationState: ApplicationStateSlice,
    user: UserSlice,
    claimSlice: ClaimSlice,
    userDataSlice: UserDataSlice,
    policySlice: PolicySlice,
    CompanySlice: CompanySlice,
    ClaimAgentSlice: ClaimAgentSlice,
    TpaDataSlice: TpaDataSlice,
    ClaimDetailsSlice: ClaimDetailsSlice,
    FiltersSlice: FiltersSlice,
    claimStatusOptionsSlice: ClaimStatusOptionsSlice,
    TpaListSlice: TpaListSlice
});

export default rootReducer;
export type RootState = ReturnType<typeof rootReducer>;
