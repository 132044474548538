import styled from 'styled-components';

export const StyledFilterContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0px 5px;
    gap: 10px;
`;

export const StyledFilterWrapper = styled.div`
    width: 100%;
    display: flex;
`;
