import { createAsyncThunk } from '@reduxjs/toolkit';
import { createClaimAPI, fetchAllClaimsAPI, downloadClaimsAPI, updateClaimAPI } from '../../../adapters';
import { ICreateClaimProps, IClaimListType, IUpdateClaimProps } from './types';


const CLAIMSLICE_ACTION_TYPES = {
    FETCH_CLAIM_LIST: 'FETCH_COMPANY_LIST',
    CREATE_CLAIM: 'CREATE_CLAIM',
    DOWNLOAD_CLAIM: 'DOWNLOAD_CLAIM'
};

export const fetchClaims = createAsyncThunk(
    CLAIMSLICE_ACTION_TYPES.FETCH_CLAIM_LIST, async (data: IClaimListType) => {
        const res = await fetchAllClaimsAPI(data);
        return res;
    });

export const createClaim = createAsyncThunk(
    CLAIMSLICE_ACTION_TYPES.CREATE_CLAIM,
    async ({
        userId,
        benefName,
        benefAge,
        priBeneficiaryName,
        relationName,
        priBenefEmpCode,
        policyId,
        insCompName,
        tpaId,
        claimDOA,
        claimDOD,
        hospitalName,
        hospitalAddress,
        diagnosis,
        claimAmount,
        claimApprovedAmt,
        deductionAmt,
        active,
        claimType,
        navigate
    }: ICreateClaimProps) => {
        const data = {
            userId,
            benefName,
            benefAge,
            priBeneficiaryName,
            relationName,
            priBenefEmpCode,
            policyId,
            insCompName,
            tpaId,
            claimDOA,
            claimDOD,
            hospitalName,
            hospitalAddress,
            diagnosis,
            claimAmount,
            claimApprovedAmt,
            deductionAmt,
            active,
            claimType
        };
        const result = await createClaimAPI(data);
        if (result?.id) {
            navigate();
        }
        return result;
    }
);

export const updateClaim = createAsyncThunk(
    CLAIMSLICE_ACTION_TYPES.CREATE_CLAIM,
    async ({
        id,
        claimPriority,
        remark,
        tpaStatus,
        status
    }: IUpdateClaimProps) => {
        if (!id) {
            return;
        }
        const data = {
            claimPriority,
            remark,
            tpaStatus,
            status
        };
        const result = await updateClaimAPI(data, id);
        return result;
    }
);

export const downloadClaims = createAsyncThunk(
    CLAIMSLICE_ACTION_TYPES.DOWNLOAD_CLAIM, async () => {
        const res = await downloadClaimsAPI();
        return res;
    }
);
