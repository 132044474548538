import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './initialState';
import { fetchTpaList } from './thunks';
import { prepareFilters } from './helper';

const tpaSlice = createSlice({
    name: 'tpaSlice',
    initialState,
    reducers: {
        resetTpaListState(state) {
            state.tpa = initialState.tpa;
        }
    },
    extraReducers: {
        [fetchTpaList.pending as any]: (state) => {
            state.tpa.loading = true;
        },
        [fetchTpaList.rejected as any]: (state, { error }) => {
            state.tpa.error = error;
            state.tpa.loading = false;
            state.tpa.data = null;
        },
        [fetchTpaList.fulfilled as any]: (state, { payload }) => {
            const data = prepareFilters(payload);
            state.tpa.error = null;
            state.tpa.loading = false;
            state.tpa.data = data;
        },
        'common/cleanup': (state) => {
            state.tpa = initialState.tpa;
        }
    }
});
export default tpaSlice.reducer;
export const { resetTpaListState } = tpaSlice.actions;
export { fetchTpaList };
