import React from 'react';
import { LoopBadge, LoopButton, Typography } from '@loophealth/loop-ui-web-library';
import {
    StyledAssignmentWrapper,
    StyledWrapper,
    StyledRowContainer,
    StyledContainerSection,
    StyledModalWrapper,
    StyledSendIcon,
    StyledTypoWrapperMargin
} from './styles';
import { SelectedRowsHeaderProps } from './types';
import { IBadgeType } from '@loophealth/loop-ui-web-library/dist/types/atoms/LoopBadge/types';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from '../../../redux';
import BulkAssignmentModal from '.';
import { handleAgentSortByClaimCount, handleToast, parseResponse } from '../../../utils/services/agents';
import { useConfirmationModalContext } from '../../../utils/hooks/modalConfirmationContext';
import { UnAssignClaim } from '../../../assets/img';
import { claimUnAssignData } from '../../../utils/services/claims';
import { IClaim } from '../../pages/SPAContainer/pages/ClaimsDirectory/types';
import { AgentClaimMap } from '../../../utils/services/claims/types';
import { claimsUnAssignment } from '../../../adapters';
import { fetchAllClaimAgents } from '../../../redux/slices/ClaimAgentSlice';
import { useToast } from '../../../utils/hooks/useToast';
import { ISelectedFilter } from '../../../redux/slices/ClaimSlice/types';
import { fetchClaims } from '../../../redux/slices/ClaimSlice';

const SelectedRowsHeader: React.FC<SelectedRowsHeaderProps> = ({
    selectedRowCount,
    selectedClaims,
    handleCancel,
    selectedClaimsList,
    selectedClaimsDetails
}) => {
    const toast = useToast();
    const dispatch = useDispatch();
    let claimAgentData = useSelector((state: ReduxState) => state.ClaimAgentSlice.claimAgentsData.data);
    const processedResponse: AgentClaimMap[] = claimUnAssignData(selectedClaimsDetails as IClaim[]);
    const claimsDataArray = useSelector((state: ReduxState) => state.claimSlice.claimList);
    const prevSelectedFilters: ISelectedFilter = claimsDataArray?.selectedFilters;
    if (claimAgentData) {
        claimAgentData = handleAgentSortByClaimCount(claimAgentData, 'asc');
    }
    const [bulkAssignIsVisible, setBulkAssignIsVisible] = React.useState<boolean>(false);
    const modalContext = useConfirmationModalContext();
    const handleUnassignClick = async () => {
        const badgeVariants = ['warning', 'accent', 'success', 'grey'];
        const isConfirmed = await modalContext.showConfirmation({
            title: '',
            message: (
                <StyledModalWrapper>
                    <StyledSendIcon $width="72px" $margin="0px" src={UnAssignClaim} />
                    <StyledTypoWrapperMargin $margin="24px 0px 12px 0px">
                        <Typography variant="large" weight="bold">
                            Are you sure you want to unassign {selectedRowCount} claims with these statuses?
                        </Typography>
                    </StyledTypoWrapperMargin>
                    <>
                        {Object.entries(selectedClaimsList?.tpaStatusCounts).map(([key, value], index) => (
                            <StyledContainerSection key={index}>
                                <Typography variant="medium" color="secondary">
                                    {value} {Number(value) > 1 ? 'Claims' : 'Claim'}
                                </Typography>
                                <LoopBadge
                                    variant="overflow"
                                    badgeType={badgeVariants[index % badgeVariants.length] as IBadgeType}
                                    text={key}
                                />
                            </StyledContainerSection>
                        ))}
                    </>
                </StyledModalWrapper>
            ),
            yesLabel: 'Unassign',
            noLabel: 'Cancel',
            variant: 'error'
        });
        if (isConfirmed) {
            if (processedResponse.length) {
                const [error, response] = await parseResponse(claimsUnAssignment(processedResponse as any));
                handleToast(error, response, toast, selectedRowCount);
                dispatch(fetchClaims(prevSelectedFilters));
                dispatch(fetchAllClaimAgents());
                handleCancel();
            }
        }
    };
    const handleBulkAssignment = () => {
        setBulkAssignIsVisible(true);
    };
    return (
        <>
            <StyledAssignmentWrapper>
                <Typography variant="small">Total {selectedRowCount} claims selected</Typography>
                <StyledWrapper>
                    <Typography variant="small" weight="bold">
                        Reassign selected claims?
                    </Typography>
                    <StyledRowContainer>
                        <LoopButton variant="outline" onClick={handleCancel}>
                            Cancel
                        </LoopButton>
                        {selectedRowCount > selectedClaimsList.nullAssigneeNameCount ? (
                            <>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <LoopButton variant="secondary" onClick={handleUnassignClick}>
                                    Unassign
                                </LoopButton>
                            </>
                        ) : null}
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <LoopButton variant="filled" onClick={handleBulkAssignment}>
                            Reassign
                        </LoopButton>
                    </StyledRowContainer>
                </StyledWrapper>
            </StyledAssignmentWrapper>
            <BulkAssignmentModal
                isVisible={bulkAssignIsVisible}
                setIsVisible={setBulkAssignIsVisible}
                selectedRowCount={selectedRowCount}
                selectedClaims={selectedClaims}
                unselectSelectedClaims={handleCancel}
                claimAgentsList={claimAgentData || []}
            />
        </>
    );
};

export default SelectedRowsHeader;
