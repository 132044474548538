import styled from 'styled-components';

export const StyledMargin = styled.span`
    margin: 15px 0px;
`;

export const StyledClaimSource = styled.div<{ $isClaimSourceTPA: boolean }>(
    (p) => `
    border-radius: 8px;
    color: white;
    background-color: ${p.$isClaimSourceTPA ? p.theme.colors.teamTurquoise : p.theme.colors.purple};
    padding: 8px;
    font-size: ${p.theme.fontSizes.label}
`
);

export const StyledActionContainer = styled.ul`
    min-width: 220px;
    min-height: 90px;
    display: flex;
    position: absolute;
    left: -235px;
    top: 0px;
    padding: 0;
    z-index: 1;
    border: 0;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.084), 0px 2px 3px rgba(0, 0, 0, 0.168);
    align-items: center;
    background-color: ${(p) => p.theme.colors.white};
    cursor: pointer;
    display: flex;
    flex-direction: column;
    /* border: 4px solid red; */
`;

export const StyledActionLI = styled.li`
    list-style: none;
    padding: 15px 20px;
    width: 100%;
    display: flex;
    font-style: normal;
    font-weight: 400;
    justify-content: center;
    background-color: ${(p) => p.theme.colors.white};
    color: ${(p) => p.theme.colors.beyondGrey};
    letter-spacing: 0.2px;
    font-size: ${(p) => p.theme.fontSizes.mediumFont};
    box-shadow: 0px -1px 3px 0px rgb(0 0 0 / 8%);
    &:hover {
        cursor: pointer;
        background-color: ${(p) => p.theme.colors.mutedBG} !important;
    }
`;

export const ArrowRight = styled.div`
    width: 0;
    height: 0;
    top: 30px;
    position: absolute;
    right: 77%;
    border-style: solid;
    border-width: 10px;
    border-color: ${(p) => p.theme.colors.white};
    border-top-color: transparent;
    border-right-color: transparent;
    border-bottom-color: transparent;
`;
