import React, { ChangeEvent } from 'react';
import { useToast } from '../../../utils/hooks/useToast';
import { StyledContainer, StyledInput } from './styles';
import { IFileSelector } from './types';

const FileSelector: React.FunctionComponent<IFileSelector> = ({
    onUpload,
    formats,
    maxFiles = 1,
    maxSize = null,
    children
}) => {
    const toast = useToast();
    const dropAreaRef = React.useRef<HTMLDivElement>(null);
    const clickAreaRef = React.useRef<HTMLInputElement>(null);
    React.useEffect(() => {
        if (dropAreaRef?.current) {
            dropAreaRef.current.addEventListener('dragover', handleDragOver);
            dropAreaRef.current.addEventListener('drop', handleDrop);
        }
        return () => {
            if (dropAreaRef?.current) {
                dropAreaRef.current.removeEventListener('dragover', handleDragOver);
                dropAreaRef.current.removeEventListener('drop', handleDrop);
            }
        };
    }, [dropAreaRef]);
    const validateFile = (rawFiles: FileList): Record<string, unknown>[] => {
        const files = [...(rawFiles as unknown as Array<Record<string, unknown>>)];
        if (files.length > maxFiles) {
            throw new Error(`Please select only ${maxFiles} file(s) at a time!`);
        }
        if (
            formats &&
            files.some(
                (file) => !formats.some((format) => (file.name as string).toLowerCase().endsWith(format.toLowerCase()))
            )
        ) {
            throw new Error(`Only following file formats are acceptable: ${formats.join(', ')}`);
        }
        if (maxSize) {
            files.forEach((file) => {
                if ((file.size as number) / 1024 / 1024 > maxSize) {
                    throw new Error(`You cannot upload a file greater than ${maxSize} MB`);
                }
            });
        }
        return files;
    };
    const handleDragOver = (e: DragEvent) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const handleDrop = (e: DragEvent) => {
        e.preventDefault();
        e.stopPropagation();
        const { files } = e.dataTransfer || {};
        if (files && files.length) {
            try {
                onUpload(validateFile(files));
            } catch (e) {
                if (toast && toast.displayToast) {
                    toast.displayToast('error', 'Failed to upload!', (e as Error).message);
                }
            }
        }
    };
    const onClick = () => {
        if (clickAreaRef.current) {
            clickAreaRef.current.click();
        }
    };
    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        const files = clickAreaRef.current?.files;
        if (files && files.length) {
            try {
                onUpload(validateFile(files));
            } catch (e) {
                if (toast && toast.displayToast) {
                    toast.displayToast('error', 'Failed to upload!', (e as Error).message);
                }
                return;
            }
        }
        e.target.value = '';
    };
    return (
        <StyledContainer ref={dropAreaRef} onClick={onClick}>
            <StyledInput ref={clickAreaRef} type="file" name="file" onChange={onChange} />
            {children}
        </StyledContainer>
    );
};

export default FileSelector;
