import React from 'react';
import DataTable from 'react-data-table-component';

const selectProps = { indeterminate: (isIndeterminate: any) => isIndeterminate };

interface IDataTableBase {
    columns: any;
    data: any;
    progressComponent: any;
    progressPending: boolean;
    persistTableHead: any;
    highlightOnHover: boolean;
    pointerOnHover: boolean;
    paginationServer: boolean;
    paginationComponent?: any;
    paginationComponentOptions?: any;
    onChangePage: any;
    currentPage: number;
    paginationPerPage: number;
    paginationDefaultPage?: number;
    paginationTotalRows?: number;
    onChangeRowsPerPage: (pageSize: number) => void;
    selectableRows: boolean;
    selectableRowsComponent?: 'input' | React.ReactNode;
    onSelectedRowsChange?: (selected: { allSelected: boolean; selectedCount: number; selectedRows: any }) => void;
    paginationRowsPerPageOptions: number[];
    clearSelectedRows?: boolean;
    onSort?: () => void;
}

const DataTableBase = (props: IDataTableBase): any => {
    return <DataTable pagination selectableRowsComponentProps={selectProps} {...props} />;
};

export default DataTableBase;
