import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { loopHTTPClient } from './adapters/backend/provider';
import MainRouter from './components/pages/router';
import { ReduxState } from './redux';

import ConfirmationModalContextProvider from './utils/hooks/modalConfirmationContext';
import { SegmentProvider } from './utils/hooks/useSegment';
import useToken from './utils/hooks/useToken';
import { getAgeFromDOB } from './utils/services/dateService';
const StyledContainer = styled.div`
    height: 100vh;
    width: 100vw;
    max-height: 100vh;
    max-width: 100vw;
    overflow-y: auto;
    background-color: ${(p) => p.theme.colors.secondary};
`;

loopHTTPClient.interceptors.request.use(
    async (request: any) => {
        if (typeof window !== 'undefined') {
            const bearerToken = await useToken().generateToken();
            if (bearerToken) {
                request.headers.Authorization = `Bearer ${bearerToken}`;
            }
            // request.headers['Content-Type'] = 'application/json'
            return request;
        } else {
            return request;
        }
    },
    (error: any) => {
        if (typeof window !== 'undefined') {
            return Promise.reject(error);
        } else {
            return error;
        }
    }
);

const App: React.FunctionComponent = () => {
    const userData = useSelector((state: ReduxState) => state.user?.userData.data);
    return (
        <ConfirmationModalContextProvider>
            <SegmentProvider
                value={{
                    userId: userData?.userId,
                    login_method_type: userData?.loginMethod,
                    login_method: userData?.loginMethodType,
                    commonMeta: {
                        user_id: userData?.userId || '',
                        first_name: userData?.firstName || '',
                        last_name: userData?.lastName || '',
                        age: getAgeFromDOB(userData?.dob?.seconds || 0).toString(),
                        gender: userData?.gender || '',
                        login_application: process.env.REACT_APP_NAME,
                        // eslint-disable-next-line new-cap
                        timezone: Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone ?? '',
                        screen: {
                            height: Math.max(document?.documentElement?.clientHeight || 0, window?.innerHeight || 0),
                            width: Math.max(document?.documentElement?.clientWidth || 0, window?.innerWidth || 0)
                        },
                        employer_name: 'loop',
                        user_type: userData?.patientType || ''
                    }
                }}
            >
                <StyledContainer>
                    <MainRouter />
                </StyledContainer>
            </SegmentProvider>
        </ConfirmationModalContextProvider>
    );
};

export default App;
