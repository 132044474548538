import { createAsyncThunk } from '@reduxjs/toolkit';
import { claimsFiltersAPI } from '../../../adapters';

const CLAIM_SLICE_ACTION_TYPES = {
    FETCH_CLAIM_FILTER_DETAILS: 'FETCH_CLAIM_FILTER_DETAILS'
};

export const fetchClaimsFilters = createAsyncThunk(CLAIM_SLICE_ACTION_TYPES.FETCH_CLAIM_FILTER_DETAILS, async () => {
    const res = await claimsFiltersAPI();
    return res;
});
