import styled from 'styled-components';

export const StyledContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    background-color: ${(p) => p.theme.colors.loopEmerald};
    height: 100vh;
    width: 100vw;
    @media (max-width: 768px) {
        padding: 20px;
        max-width: 100%;
        box-sizing: border-box;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
    }
`;
export const StyledPanelContainer = styled.div`
    height: 100%;
    max-width: 60%;
    width: 58%;
    display: flex;
    justify-content: center;
    @media (max-width: 768px) {
        display: none;
    }
`;

export const StyledContentContainer = styled.div(
    (p) => `
    height: 80%;
    width:35%;
    padding: 60px 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    // margin-right: 5%;
    border-radius:25px;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    background-color: ${p.theme.colors.white};
`
);
