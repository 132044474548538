import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './initialState';
import { fetchAllCompanies } from './thunks';

const companySlice = createSlice({
    name: 'companySlice',
    initialState,
    reducers: {
        // userDetailByMobileEmail(state,{payload}){
        //     state.companiesData.data=state.companiesDatas.data
        // },
        resetState(state) {
            state.companiesData = initialState.companiesData;
        }
    },
    extraReducers: {
        [fetchAllCompanies.pending as any]: (state) => {
            state.companiesData.loading = true;
        },
        [fetchAllCompanies.rejected as any]: (state, { error }) => {
            state.companiesData.error = error;
            state.companiesData.loading = false;
            state.companiesData.data = null;
        },
        [fetchAllCompanies.fulfilled as any]: (state, { payload }) => {
            const companiesData = payload?.companies.map((elem: any) => ({
                name: elem?.name as string,
                value: elem?.id as string
            }));
            state.companiesData.error = false;
            state.companiesData.loading = false;
            state.companiesData.data = companiesData;
        },
        'common/cleanup': (state) => {
            state.companiesData = initialState.companiesData;
        }
    }
});
export default companySlice.reducer;
export const { resetState } = companySlice.actions;
export { fetchAllCompanies };
