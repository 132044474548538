import React from 'react';
import { StyledCheckboxContainer, StyledInput, StyledAgentClaimsSection, StyledWrapper } from './styles';

import { NameIcon } from '../../atoms';
import { LoopTag, Typography } from '@loophealth/loop-ui-web-library';
import theme from '../../../theme';
import { IClaimAssignmentCheckbox } from './types';
import { StyledTabWrapper } from '../../pages/SPAContainer/pages/ClaimsDetails/styles';

const ClaimAssignmentCheckbox: React.FunctionComponent<IClaimAssignmentCheckbox> = ({
    checked,
    onChange,
    agent,
    variant
}) => {
    const [checkboxChecked, setCheckboxChecked] = React.useState<boolean>(false);

    React.useEffect(() => {
        setCheckboxChecked(checked);
    }, [checked]);

    const handleCheckboxChange = () => {
        setCheckboxChecked(!checkboxChecked);
        onChange(agent?.id); // Pass the correct argument to the onChange event handler
    };

    return (
        <StyledCheckboxContainer>
            <StyledWrapper>
                <StyledInput type="checkbox" checked={checkboxChecked} onChange={handleCheckboxChange} />
                <NameIcon name={agent.name} color={theme.colors.consultViolet} />{' '}
                <StyledAgentClaimsSection>
                    <Typography variant="extraSmall">{agent.name}</Typography>
                    <Typography variant="extraSmall">
                        {agent.claimCount}+
                        <Typography variant="extraSmall" color="emerald">
                            {agent?.claims?.length || 0}
                        </Typography>
                    </Typography>
                </StyledAgentClaimsSection>
            </StyledWrapper>
            <StyledTabWrapper>
                <LoopTag variant={variant}>{agent?.claimCount + (agent?.claims?.length || 0)}</LoopTag>
            </StyledTabWrapper>
        </StyledCheckboxContainer>
    );
};

export default ClaimAssignmentCheckbox;
