import React, { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Header } from '../../containers';
import { logoutUser } from '../LoginContainer/redux/slices/UserSlice';
import { StyledContainer, StyledOutlet } from './styles';
import { ToastProvider } from '../../../utils/hooks/useToast';
import { fetchClaims } from '../../../redux/slices/ClaimSlice/thunks';
import { resetClaimsState } from '../../../redux/slices/ClaimSlice';
import { fetchAllCompanies } from '../../../redux/slices/CompanySlice/thunks';
import { resetClaimAgentsState } from '../../../redux/slices/ClaimAgentSlice';
import { fetchAllClaimAgents } from '../../../redux/slices/ClaimAgentSlice/thunks';
import { fetchClaimsFilters } from '../../../redux/slices/FiltersSlice';
import { fetchClaimStatusOptions, resetClaimStatusOptions } from '../../../redux/slices/ClaimStatusOptionsSlice';
import { resetState } from '../../../redux/slices/CompanySlice';
import { fetchTpaList, resetTpaListState } from '../../../redux/slices/TpaListSlice';
import { CLAIMS_FILTER_PARAMS } from '../../../utils/constants';
import { fetchUserRole } from '../../../redux/slices/UserDataSlice/thunks';
import { resetUserDataState } from '../../../redux/slices/UserDataSlice';
import { ReduxState } from '../../../redux';
import { AgentRoles } from '../../../redux/slices/UserDataSlice/types';

const SPAContainer: React.FunctionComponent = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const signOut = () => {
        dispatch(resetClaimsState());
        dispatch(resetState());
        dispatch(logoutUser());
    };
    const goHome = () => {
        navigate('/');
    };
    const checkAgentPermission = useSelector((state: ReduxState) => state.userDataSlice?.userPermissions?.data);
    useEffect(() => {
        const CLAIMS_FILTER_PARAMS_UPDATED = {
            ...CLAIMS_FILTER_PARAMS,
            actionableClaims: false
        };

        if (checkAgentPermission?.level === AgentRoles.L1 || checkAgentPermission?.level === AgentRoles.L2) {
            if (!CLAIMS_FILTER_PARAMS.agentId) CLAIMS_FILTER_PARAMS.agentId = checkAgentPermission.id;
            dispatch(fetchClaims(CLAIMS_FILTER_PARAMS));
        } else {
            dispatch(fetchClaims(CLAIMS_FILTER_PARAMS_UPDATED));
        }
    }, [checkAgentPermission?.level]);

    React.useEffect(() => {
        dispatch(resetUserDataState());
        dispatch(resetClaimsState());
        dispatch(resetTpaListState());
        dispatch(fetchClaimsFilters());
        dispatch(resetState());
        dispatch(resetClaimAgentsState());
        dispatch(fetchAllCompanies());
        dispatch(fetchAllClaimAgents());
        dispatch(resetClaimStatusOptions());
        dispatch(fetchClaimStatusOptions());
        dispatch(fetchUserRole());
        dispatch(fetchTpaList());
    }, []);

    return (
        <ToastProvider>
            <StyledContainer>
                <Header goHome={goHome} signout={signOut} />
                <StyledOutlet>
                    <Outlet />
                </StyledOutlet>
            </StyledContainer>
        </ToastProvider>
    );
};

export default SPAContainer;
