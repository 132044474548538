import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchClaimStatusOptionsAPI } from '../../../adapters';

const CLAIM_SLICE_ACTION_TYPES = {
    FETCH_FILTERS: 'FETCH_FILTERS'
};

export const fetchClaimStatusOptions = createAsyncThunk(
    CLAIM_SLICE_ACTION_TYPES.FETCH_FILTERS,
    async () => {
        const res = await fetchClaimStatusOptionsAPI();
        return res;
    }
);
