import styled from 'styled-components';

export const StyledTextArea = styled.textarea<{ isError: boolean; disabled: boolean }>(
    (p) => `
    min-width: 100%;
    width: 100%;
    box-sizing: border-box;
    -webkit-box-sizing:border-box;
    -moz-box-sizing: border-box;
    border: 2px solid ${p.isError ? p.theme.colors.error : p.theme.colors.mutedTransparent};
    color: ${p.isError ? p.theme.colors.error : p.disabled ? p.theme.colors.muted : p.theme.colors.black};
    font-size: ${p.theme.fontSizes.body};
    border-radius: 8px;
    box-shadow: 0px 4px 10px ${p.theme.colors.activeBG};
    padding: 12px 18px;
    padding-right:30%;
    min-height:70px;
    max-height:200px;
    &::placeholder {
        color: ${p.isError ? p.theme.colors.error : p.theme.colors.muted};
    }
    &:focus {
        border-color: ${p.theme.colors.loopEmerald};
    }
`
);
