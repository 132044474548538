import styled from 'styled-components';

export const StyledCheckboxContainer = styled.div`
    display: flex;
    padding: 10px;
    width: 254px;
    border-radius: 6px;
    background-color: ${(p) => p.theme.colors.white};
    align-items: center;
    justify-content: space-between;
`;

export const StyledInput = styled.input.attrs({ type: 'checkbox' })`
    width: 20px;
    height: 20px;
    border-radius: 4px;
    margin-right: 15px;
    cursor: pointer;
    accent-color: ${(p) => p.theme.colors.loopEmerald};
    &::checked {
        background-color: ${(p) => p.theme.colors.loopEmerald};
    }
`;

export const StyledAgentClaimsSection = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 8px;
    text-overflow: ellipsis;
`;

export const StyledWrapper = styled.div`
    display: flex;
    align-items: center;
`;
