import React from 'react';
import { StyledTextArea } from './styles';
import { ITextarea } from './types';

const Textarea: React.FunctionComponent<ITextarea> = ({ isError, maxLen, disabled, children, ...rest }) => {
    return (
        <StyledTextArea isError={isError} disabled={disabled} {...rest} maxLength={maxLen ? maxLen : 2048}>
            {children}
        </StyledTextArea>
    );
};

export default Textarea;
