import { IAdapterData, IError } from '../../types';
export interface IClaimSlice {
    claimList: IClaimList;
    createClaim: ICreateClaim;
    companyList: ICompanyList;
    employeeList: IEmployeeList;
    claimAgentList: IClaimAgentList;
}
export interface ICreateClaim extends IAdapterData {
    data: null;
    loading: boolean;
    error: IError | null;
}
export interface IClaimList extends IAdapterData {
    data: IClaims | null;
    loading: boolean;
    error: IError | null;
    metaData: {
        pageNo: number;
        hasPrev: boolean;
        hasNext: boolean;
        nextPageId: number;
        prevPageId: number;
    };
    selectedFilters: ISelectedFilter;
}

export interface ISelectedFilter {
    claimId: string;
    polHolderName: string;
    assignTo: any;
    statusName: string;
    priBeneficiaryName: string;
    startDate: Date;
    endDate: Date;
    claimType: string;
    pageSize: number;
    pageNo: number;
}

export interface IClaims {
    claims: IClaimData[] | null;
    totalCount: number;
}

export interface IFilters {
    fetchClaimsList(data: {
        claimId: string;
        companyId: string;
        assignTo: string;
        statusName: string;
        //       priBeneficiaryName: string;
        startDate: any;
        endDate: any;
        claimType: string;
        userId: string;
    }): void;
    prevSelectedFilters: any;
    resetFilters: any;
}
export interface ICompanyList extends IAdapterData {
    data: any | null;
    loading: boolean;
    error: IError | null;
}
export interface IEmployeeList extends IAdapterData {
    data: any | null;
    loading: boolean;
    error: IError | null;
}
export interface IClaimAgentList extends IAdapterData {
    data: any | null;
    loading: boolean;
    error: IError | null;
}
export interface IClaimPriorities {
    label: string;
}

export interface ClaimPriority {
    VIP: VIP;
    TAT_EXCEED: TAT_EXCEED;
}

export enum FilterType {
    MULTISELECT = 'multiselect',
    DROPDOWN = 'dropdown'
}

export type ClaimStatusValues =
    | 'under_progress'
    | 'under_progress_query'
    | 'processed'
    | 'repudiated'
    | 'settled'
    | 'closed'
    | 'not_mapped';

export type ClaimPriorityValues = VIP | TAT_EXCEED;

export type VIP = 'vip';

export type TAT_EXCEED = 'tat_exceed';

export type ClaimTypeValues = 'cashless' | 'reimbursement';

export interface IClaimData {
    id?: string;
    policyNo: string;
    oldClaimId?: string | null;
    employeeName: string;
    email?: string | null;
    employeeCode: string;
    benificiaryName: string;
    benificiaryGender: string;
    benificiaryAge: number;
    relationship: string;
    type: ClaimTypeValues;
    claimAmount: number;
    approvedAmount: number | null;
    sumInsured: number;
    insuranceName: string;
    dischargeSummary?: string | null;
    disease?: string | null;
    hospital: string;
    currentStatus: ClaimStatusValues;
    uhid: string;
    tpaName?: string | null;
    tpaId: string;
    tpaClaimId: string;
    company: string;
    companyId: string;
    userId?: string | null;
    deniedReason?: string | null;
    policyStartDate: Date;
    policyEndDate: Date;
    admissionAt: Date | null;
    dischargedAt?: Date | null;
    chequeReceivedAt?: Date | null;
    claimReceivedAt: Date;
    ticketId?: string | null;
}
// export interface IClaimListType {
//     claimId: string;
//     polHolderName: string;
//     assignTo: string;
//     statusName: string;
//     priBeneficiaryName: string;
//     startDate: any;
//     endDate: any;
//     claimType: string;
// }
export interface IClaimListType {
    search?: string;
    type?: string;
    status?: string;
    assignTo?: string[];
    priority?: string;
    sdate?: Date;
    edate?: Date;
    perPage?: number;
    page?: number;
    sortDirection?: 'asc' | 'desc';
}
export interface ICreateClaimProps {
    userId: string;
    benefName: string;
    benefAge: string;
    priBeneficiaryName: string;
    relationName: string;
    priBenefEmpCode: string;
    policyId: string;
    insCompName: string;
    tpaId: string;
    claimDOA?: string;
    claimDOD?: string;
    hospitalName: string;
    hospitalAddress: string;
    diagnosis: string;
    claimAmount: number;
    claimApprovedAmt: number;
    deductionAmt: number;
    active: boolean;
    claimType: string;
    navigate(): void;
}
export interface IUpdateClaimProps {
    id?: string;
    claimPriority?: any;
    remark?: {
        agentName: string;
        agentLevel: string;
        comment: string;
    };
    tpaStatus?: string;
    status?: {
        agentName?: string;
        agentLevel?: string;
        status?: string;
    };
}
