import { IClaimSlice } from './types';
import { DEFAULT_PAGE_SIZE } from '../../../utils/constants';

export const initialState: IClaimSlice = {
    claimList: {
        data: null,
        error: null,
        loading: false,
        selectedFilters: {
            claimId: '',
            polHolderName: '',
            assignTo: '',
            statusName: '',
            priBeneficiaryName: '',
            startDate: new Date(new Date().setDate(new Date().getDate() - 29)),
            endDate: new Date(),
            claimType: '',
            pageSize: DEFAULT_PAGE_SIZE,
            pageNo: 0
        },
        metaData: { pageNo: 0, hasPrev: false, hasNext: false, nextPageId: 0, prevPageId: 0 }
    },
    companyList: {
        data: null,
        error: null,
        loading: false
    },
    employeeList: {
        data: null,
        error: null,
        loading: false
    },
    claimAgentList: {
        data: null,
        error: null,
        loading: false
    },
    createClaim: {
        data: null,
        loading: false,
        error: null
    }
};
