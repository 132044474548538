import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useSegment from '../../../../../../utils/hooks/useSegment';
import { ErrorContainer } from '../../../../../atoms';
import { loginWithOAuth, clearUserError } from '../../../redux/slices/UserSlice';
import { SEGMENT_ACTIONS } from '../../../utils/constants';
import { LoginOAuth } from '../../containers';
import { StyledContent, StyledHeading, StyledOAuthContainer, StyledTitle } from './styles';
import { ILoginPage } from './types';
const LoginPage: React.FunctionComponent<ILoginPage> = ({ title, subTitle }) => {
    const dispatch = useDispatch();
    const trackTask = useSegment('task');
    const userData = useSelector((state: any) => state.user.userData);

    const oAuthLogin = () => {
        dispatch(clearUserError());
        dispatch(loginWithOAuth());
    };
    React.useEffect(() => {
        if (!userData.loading && !userData.error && userData.data) {
            const segmentAction = SEGMENT_ACTIONS.TASK.LOGIN_SUCCESS;
            trackTask({
                name: segmentAction.name,
                properties: {
                    login_method: (segmentAction.properties?.login_method as (loginMethod: string) => string)(
                        userData.loginMethodType
                    )
                }
            });
        } else if (!userData.loading && userData.error) {
            const segmentAction = SEGMENT_ACTIONS.TASK.LOGIN_FAIL;
            trackTask({
                name: segmentAction?.name ?? '',
                properties: {
                    login_method: (segmentAction.properties?.login_method as (loginMethod: string) => string)(
                        userData.loginMethodType
                    )
                }
            });
        }
    }, [userData]);
    return (
        <StyledContent>
            <StyledTitle>{title}</StyledTitle>
            <StyledHeading>{subTitle}</StyledHeading>
            <StyledOAuthContainer>
                <LoginOAuth signInWithOAuth={oAuthLogin} />
            </StyledOAuthContainer>
            {userData.error && <ErrorContainer message={userData.error.message} />}
        </StyledContent>
    );
};

export default LoginPage;
