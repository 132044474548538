import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './initialState';
import { fetchClaimDetailsById, fetchClaimRemarks, addClaimRemarkById, updateClaimPriorityById } from './thunks';

const claimDetailsSlice = createSlice({
    name: 'claimDetailsSlice',
    initialState,
    reducers: {
        resetClaimsDetailsState(state) {
            state.claimDetails = initialState.claimDetails;
            state.remarks = initialState.remarks;
            state.addRemarkState = initialState.addRemarkState;
            state.claimPriorityState = initialState.claimPriorityState;
        }
    },
    extraReducers: {
        [fetchClaimDetailsById.pending as any]: (state) => {
            state.claimDetails.loading = true;
        },
        [fetchClaimDetailsById.rejected as any]: (state, { error }) => {
            state.claimDetails.error = error;
            state.claimDetails.loading = false;
            state.claimDetails.data = null;
        },
        [fetchClaimDetailsById.fulfilled as any]: (state, { payload }) => {
            state.claimDetails.error = null;
            state.claimDetails.loading = false;
            state.claimDetails.data = payload?.claim;
        },
        [fetchClaimRemarks.pending as any]: (state) => {
            state.remarks.loading = true;
        },
        [fetchClaimRemarks.rejected as any]: (state, { error }) => {
            state.remarks.error = error;
            state.remarks.loading = false;
            state.remarks.data = null;
        },
        [fetchClaimRemarks.fulfilled as any]: (state, { payload }) => {
            state.remarks.error = null;
            state.remarks.loading = false;
            state.remarks.data = payload?.remarks;
        },
        [addClaimRemarkById.pending as any]: (state) => {
            state.addRemarkState.loading = true;
        },
        [addClaimRemarkById.rejected as any]: (state, { error }) => {
            state.addRemarkState.error = error;
            state.addRemarkState.loading = false;
            state.addRemarkState.data = null;
        },
        [addClaimRemarkById.fulfilled as any]: (state, { payload }) => {
            state.addRemarkState.error = null;
            state.addRemarkState.loading = false;
            state.addRemarkState.data = payload;
        },
        [updateClaimPriorityById.pending as any]: (state) => {
            state.claimPriorityState.loading = true;
        },
        [updateClaimPriorityById.rejected as any]: (state, { error }) => {
            state.claimPriorityState.error = error;
            state.claimPriorityState.loading = false;
            state.claimPriorityState.data = null;
        },
        [updateClaimPriorityById.fulfilled as any]: (state, { payload }) => {
            state.claimPriorityState.error = null;
            state.claimPriorityState.loading = false;
            state.claimPriorityState.data = payload;
        },
        'common/cleanup': (state) => {
            state.claimDetails = initialState.claimDetails;
            state.remarks = initialState.remarks;
            state.addRemarkState = initialState.addRemarkState;
            state.claimPriorityState = initialState.claimPriorityState;
        }
    }
});
export default claimDetailsSlice.reducer;
export const { resetClaimsDetailsState } = claimDetailsSlice.actions;
export { fetchClaimDetailsById, fetchClaimRemarks, addClaimRemarkById, updateClaimPriorityById };
