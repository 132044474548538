import React, { useState, useEffect } from 'react';
import {
    StyledModalContainer,
    StyledDetailsWrapper,
    StyledDetailSection,
    StyledContentWrapper,
    StyledSelectionWrapper,
    StyledSelectionSection,
    StyledSortSection,
    StyledSelectionTab,
    StyledInfoTAB,
    StyledSortIcon,
    StyledSelectionContainer,
    StyledButtonContainer,
    StyledContainer,
    StyledButtonWrapper,
    StyledTypoWrapperMargin,
    StyledAssignModalWrapper
} from './styles';
import { Modal } from '../../atoms';
import { IClaimAgent, IBulkAssignmentModal, IAgentSortType, IAssignmentData } from './types';
import { LoopButton, Typography } from '@loophealth/loop-ui-web-library';
import { InfoIcon, SortIcon } from '../../../assets/img';
import ClaimAssignmentCheckbox from '../ClaimAssignmentCheckbox';
import { useConfirmationModalContext } from '../../../utils/hooks/modalConfirmationContext';
import { StyledModalWrapper } from './styles';
import { StyledSendIcon } from './styles';
import { useToast } from '../../../utils/hooks/useToast';
import { fetchClaims } from '../../../redux/slices/ClaimSlice';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from '../../../redux';
import {
    assignClaimsToAgents,
    getTabVariant,
    handleAgentSortByClaimCount,
    handleToast,
    parseResponse
} from '../../../utils/services/agents';
import { claimsAssignment } from '../../../adapters';
import { ISelectedFilter } from '../../../redux/slices/ClaimSlice/types';
import { fetchAllClaimAgents } from '../../../redux/slices/ClaimAgentSlice';
const BulkAssignmentModal: React.FunctionComponent<IBulkAssignmentModal> = ({
    isVisible,
    setIsVisible,
    selectedClaims,
    selectedRowCount,
    claimAgentsList,
    unselectSelectedClaims
}) => {
    const [agentList, setAgentList] = useState<IClaimAgent[]>(claimAgentsList ?? []);
    const [selectAll, setSelectAll] = useState<boolean>(false);
    const [sortOrder, setSortOrder] = useState<IAgentSortType>('desc');
    const toast = useToast();
    const dispatch = useDispatch();
    const claimsDataArray = useSelector((state: ReduxState) => state.claimSlice.claimList);
    const prevSelectedFilters: ISelectedFilter = claimsDataArray?.selectedFilters;

    const handleCheckboxChange = (agentId: string) => {
        const updatedAgents = agentList.map((agent) => {
            if (agent.id === agentId) {
                return { ...agent, isSelected: !agent?.isSelected, claims: [] };
            }
            return { ...agent, claims: [] };
        });
        const agentsData = assignClaimsToAgents(selectedClaims, updatedAgents);
        setAgentList(agentsData);
    };

    useEffect(() => {
        handleSelectAll();
    }, []);

    const selectedAgents = agentList.filter((row) => row.isSelected);
    const totalDistributeClaims = selectedAgents?.length ? Math.floor(selectedRowCount / selectedAgents?.length) : 0;
    const handleSelectAll = () => {
        let agents = [];
        if (!selectAll) {
            agents = agentList.map((agent) => {
                return { ...agent, isSelected: true, claims: [] };
            });
        } else {
            agents = agentList.map((agent) => {
                return { ...agent, isSelected: false, claims: [] };
            });
        }
        setSelectAll((prevSelectAll) => !prevSelectAll);
        const agentsData = assignClaimsToAgents(selectedClaims, agents);
        setAgentList(agentsData);
    };

    const handleAgentSorting = () => {
        const sortedAgentList = handleAgentSortByClaimCount(agentList, sortOrder);
        setAgentList(sortedAgentList);
        setSortOrder((prevSortOrder) => (prevSortOrder === 'asc' ? 'desc' : 'asc'));
    };

    const modalContext = useConfirmationModalContext();
    const handleAssignClick = async () => {
        setIsVisible(false);
        const isConfirmed = await modalContext.showConfirmation({
            title: '',
            message: (
                <StyledModalWrapper>
                    <StyledSendIcon $width="72px" $margin="0px" src={InfoIcon} />
                    <StyledTypoWrapperMargin $margin="24px 0px 12px 0px">
                        <Typography variant="large" weight="bold">
                            Are you sure you want to reassign {selectedRowCount} claims to {selectedAgents?.length}{' '}
                            selected agents?
                        </Typography>
                    </StyledTypoWrapperMargin>

                    <Typography variant="small">
                        This will reassign the {totalDistributeClaims} claims each to the agents.
                    </Typography>
                </StyledModalWrapper>
            ),
            yesLabel: 'Confirm Reassign',
            noLabel: 'Cancel'
        });
        if (isConfirmed) {
            const assignedAgents: IClaimAgent[] = agentList.filter(
                (agent: IClaimAgent) => Array.isArray(agent.claims) && agent.claims.length > 0
            );
            const assignedClaimsAgents: IAssignmentData[] = assignedAgents.map((item) => ({
                agentId: item.id,
                claimIds: item.claims
            }));
            if (assignedClaimsAgents.length) {
                const [error, response] = await parseResponse(claimsAssignment(assignedClaimsAgents as any));
                handleToast(error, response, toast, selectedRowCount);
                unselectSelectedClaims();
                setIsVisible(false);
                dispatch(fetchClaims(prevSelectedFilters));
                dispatch(fetchAllClaimAgents());
            }
        } else {
            setIsVisible(true);
        }
    };

    return (
        <StyledAssignModalWrapper $display={true}>
            <Modal
                setIsVisible={setIsVisible}
                isVisible={isVisible}
                title={`Reassign ${selectedClaims?.length} claims to the agents?`}
            >
                <StyledModalContainer>
                    <StyledDetailsWrapper>
                        <StyledTypoWrapperMargin $margin="0px 24px 0px 0px">
                            <Typography variant="medium">Total claim distribution</Typography>
                        </StyledTypoWrapperMargin>

                        <StyledDetailSection>
                            <Typography variant="large" weight="semiBold">
                                {selectedRowCount}
                            </Typography>
                            <Typography variant="small">Claims to be assigned</Typography>
                        </StyledDetailSection>
                        <StyledDetailSection>
                            <Typography variant="large" weight="semiBold">
                                {selectedAgents?.length}/{claimAgentsList?.length}
                            </Typography>
                            <Typography variant="small">Agents selected</Typography>
                        </StyledDetailSection>
                        <StyledDetailSection>
                            <Typography variant="large" weight="semiBold">
                                {totalDistributeClaims} each
                            </Typography>
                            <Typography variant="small">Current claim distribution</Typography>
                        </StyledDetailSection>
                    </StyledDetailsWrapper>
                    <StyledContentWrapper>
                        <StyledSelectionWrapper>
                            <StyledSelectionSection>
                                <StyledTypoWrapperMargin $margin="0px 24px 0px 0px">
                                    <Typography variant="medium" weight="bold">
                                        {selectedAgents?.length}/{claimAgentsList?.length} agents selected
                                    </Typography>
                                </StyledTypoWrapperMargin>
                                <>
                                    <StyledSelectionTab
                                        onClick={() => !selectAll && handleSelectAll()}
                                        $selected={!selectAll}
                                    >
                                        Select all
                                    </StyledSelectionTab>
                                    <StyledSelectionTab
                                        onClick={() => selectAll && handleSelectAll()}
                                        $selected={selectAll}
                                    >
                                        Deselect all
                                    </StyledSelectionTab>
                                </>
                            </StyledSelectionSection>
                            <StyledSortSection>
                                <Typography variant="medium">SortBy</Typography>&nbsp;
                                <StyledInfoTAB onClick={handleAgentSorting}>
                                    {sortOrder === 'asc' ? 'Min' : 'Max'}
                                    <StyledSortIcon src={SortIcon} />
                                </StyledInfoTAB>
                            </StyledSortSection>
                        </StyledSelectionWrapper>
                        <StyledSelectionContainer>
                            <StyledContainer>
                                {agentList.map((agent, index) => (
                                    <ClaimAssignmentCheckbox
                                        key={index}
                                        variant={getTabVariant(index, claimAgentsList?.length || 0, sortOrder)}
                                        checked={agent.isSelected || false}
                                        totalDistributeClaims={totalDistributeClaims}
                                        onChange={(id: string) => {
                                            handleCheckboxChange(id);
                                        }}
                                        agent={agent}
                                    />
                                ))}
                            </StyledContainer>
                        </StyledSelectionContainer>
                        <StyledButtonWrapper>
                            <StyledButtonContainer>
                                <LoopButton variant="secondary" onClick={() => setIsVisible(false)}>
                                    Cancel
                                </LoopButton>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <LoopButton variant="filled" onClick={handleAssignClick}>
                                    Reassign
                                </LoopButton>
                            </StyledButtonContainer>
                        </StyledButtonWrapper>
                    </StyledContentWrapper>
                </StyledModalContainer>
            </Modal>
        </StyledAssignModalWrapper>
    );
};

export default BulkAssignmentModal;
