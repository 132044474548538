import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const StyledContainer = styled.div`
    min-height: 70px;
    height: 70px;
    width: 100%;
    max-width: 100%;
    border-bottom: 1px solid ${(p) => p.theme.colors.border};
    background-color: ${(p) => p.theme.colors.white};
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 30px;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
`;
export const StyledLogo = styled.img`
    max-width: 140px;
    height: auto;
    cursor: pointer;
`;

export const StyledHighlight = styled.span`
    color: ${(p) => p.theme.colors.primary};
`;

export const StyledNavbar = styled.nav`
    display: flex;
    items-center: center;
    padding: 0 24px;
    flex: 1;
`;

export const StyledNavLink = styled(Link)`
    cursor: pointer;
    text-decoration: none;
    font-size: ${(p) => p.theme.fontSizes.mediumFont};
    color: ${(p) => p.theme.colors.beyondGrey};
    text-decoration: none;
    &:focus, &:hover, &:visited, &:link, &:active {
        text-decoration: none;
    }
`;

export const StyledMenuContainer = styled.div`
    display: flex;
    align-items: center;
    position: relative;
`;
export const StyledLogoutIcon = styled.img<{
    $rotate: boolean;
}>`
    transform: ${(p) => (p.$rotate ? 'rotate(90deg)' : 'rotate(270deg)')};
    cursor: pointer;
    &:hover {
        text-decoration: underline;
    }
`;

export const StyledLogoutContainer = styled.div`
    width: 190px;
    height: 40px;
    display: flex;
    position: absolute;
    top: 45px;
    z-index: 1;
    border: 0;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.084), 0px 2px 3px rgba(0, 0, 0, 0.168);
    align-items: center;
    background-color: ${(p) => p.theme.colors.white};
    cursor: pointer;
`;
export const ArrowUp = styled.div`
    width: 0;
    height: 0;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-bottom: 15px solid ${(p) => p.theme.colors.white};
    position: absolute;
    right: 45%;
    margin-top: 10px;
`;

export const StyledModalWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 20px;
`;

export const StyledSendIcon = styled.img<{
    $width?: string;
    $margin?: string;
}>`
    max-height: ${(p) => p.$width ?? '24px'};
    margin-left: ${(p) => p.$margin ?? '10px'};
`;
