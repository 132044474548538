import React from 'react';
import { CloseIcon, WarningIcon } from '../../../assets/img';
import { StyledErrorContainer, StyledCloseIcon, StyledSubtitle } from './styles';
import { IErrorContainer } from './types';

const ErrorContainer: React.FunctionComponent<IErrorContainer> = ({ message }) => {
    const [showError, setShowError] = React.useState<boolean>(true);
    return showError ? (
        <StyledErrorContainer>
            <img src={WarningIcon} />
            {/* <StyledTitle>Something went wrong!</StyledTitle> */}
            <StyledSubtitle>{message}</StyledSubtitle>
            <StyledCloseIcon src={CloseIcon} onClick={() => setShowError(!showError)} />
        </StyledErrorContainer>
    ) : (
        <></>
    );
};

export default ErrorContainer;
