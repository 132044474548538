import React, { useState } from 'react';
import { StyledModalContainer, StyledAssignModalWrapper, StyledContainerWrapper } from './styles';
import { Modal, Table, TableData, TableHeading, TableRow } from '../../atoms';
import { IBulkAssignmentModal } from './types';
import { Loader, Typography } from '@loophealth/loop-ui-web-library';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from '../../../redux';
import moment from 'moment';
import { fetchClaimTpaDetails } from '../../../redux/slices/TpaDataSlice';
import { defaultDateFormat } from '../../../utils/services/dateService';

const ClaimDetailsModal: React.FunctionComponent<IBulkAssignmentModal> = ({ isVisible, setIsVisible }) => {
    const dispatch = useDispatch();
    const { claimDetails } = useSelector((state: ReduxState) => state.ClaimDetailsSlice);
    const { claimTpaInfo } = useSelector((state: ReduxState) => state.TpaDataSlice);
    const [claimsStatusData, setClaimsStatusData] = useState(claimTpaInfo.data ?? []);
    React.useEffect(() => {
        const policyNumber = claimDetails.data?.polInsurerPolNo || claimDetails.data?.policyNo;
        if (policyNumber) {
            const today = new Date();
            let startDate;
            const calculatedDate = moment(today).subtract(7, 'days').toDate();
            startDate = moment(calculatedDate).format('DD/MM/YYYY');
            if (claimDetails.data.tpaName && claimDetails.data.tpaName.toLowerCase() == 'health india') {
                startDate = moment(claimDetails.data?.claimReceivedAt).subtract(1, 'year').toDate();
                startDate = moment(startDate).format('DD/MM/YYYY');
            }
            dispatch(
                fetchClaimTpaDetails({
                    policyNo: policyNumber,
                    endDate: defaultDateFormat(today as any),
                    startDate: startDate,
                    empId: claimDetails.data?.employeeCode,
                    userId: claimDetails.data?.userId,
                    claimType: claimDetails.data?.type,
                    claimNo: claimDetails.data?.tpaClaimId,
                    tpaId: claimDetails.data?.tpaId
                })
            );
        }
    }, [claimDetails]);

    React.useEffect(() => {
        if (claimTpaInfo.data) {
            let result;
            if (claimDetails.data?.tpaName.toLowerCase().includes('care')) {
                result = claimTpaInfo.data?.claimTrackingDetailsGenieIntIO?.filter((item: any) => {
                    if (item.claimNumber == claimDetails.data?.tpaClaimId) {
                        return item;
                    }
                });
            } else if (claimDetails.data?.tpaName.toLowerCase().includes('icici')) {
                result = claimTpaInfo.data?.filter((item: any) => item.claimNumber === claimDetails.data?.tpaClaimId);
            } else {
                result = claimTpaInfo.data?.filter((item: any) => {
                    if ('TPA_CLAIM_NO' in item && item.TPA_CLAIM_NO === claimDetails.data?.tpaClaimId) {
                        return item;
                    } else if ('tpA_CLAIM_NO' in item && item.tpA_CLAIM_NO === claimDetails.data?.tpaClaimId) {
                        return item;
                    } else if ('UNIQUE_CLAIM' in item && item.UNIQUE_CLAIM === claimDetails.data?.tpaClaimId) {
                        return item;
                    } else if ('tpaClaimId' in item && item.tpaClaimId === claimDetails.data?.tpaClaimId) {
                        return item;
                    }
                });
            }
            setClaimsStatusData(result);
        }
    }, [claimTpaInfo]);
    return (
        <StyledAssignModalWrapper $display={true}>
            <Modal setIsVisible={setIsVisible} isVisible={isVisible} title={`Claim Details `}>
                <StyledModalContainer>
                    {claimTpaInfo.loading || claimDetails.loading ? (
                        <Loader inverted />
                    ) : (
                        <>
                            {claimTpaInfo.error ? (
                                <Typography variant="medium">{claimTpaInfo.error?.message}</Typography>
                            ) : (
                                <Table>
                                    {/* <Typography variant="medium">Claim Details</Typography> */}
                                    {claimsStatusData?.length ? (
                                        claimsStatusData?.map((row: any, index: number) => {
                                            return (
                                                <StyledContainerWrapper key={index}>
                                                    {Object.keys(row).map((key, innerIndex) => (
                                                        <TableRow key={innerIndex}>
                                                            <TableHeading>{key}</TableHeading>
                                                            <TableData>
                                                                {typeof row[key] === 'object' ? null : row[key]}
                                                            </TableData>
                                                        </TableRow>
                                                    ))}
                                                </StyledContainerWrapper>
                                            );
                                        })
                                    ) : (
                                        <Typography variant="medium">No TPA Status</Typography>
                                    )}
                                </Table>
                            )}
                        </>
                    )}
                </StyledModalContainer>
            </Modal>
        </StyledAssignModalWrapper>
    );
};

export default ClaimDetailsModal;
