import { Form, LoopTable, Typography } from '@loophealth/loop-ui-web-library';
import React, { ReactNode, useEffect, useState } from 'react';
import { ClaimStatusMapping } from '../../../../../adapters/backend';
import { TickIcon } from '../../../../../assets/img';
import { useConfirmationModalContext } from '../../../../../utils/hooks/modalConfirmationContext';
import { useToast } from '../../../../../utils/hooks/useToast';
import { StyledModalWrapper, StyledSendIcon, StyledTypoWrapperMargin } from '../ClaimsDirectory/styles';
import { StyledHeader, StyledTableContainer } from './styles';

interface ITableData extends Record<string, unknown> {
    'TPA claim status (from MIS)': string
    'Loop claim status': string | ReactNode
    'App Status': string
    'Status Tag': string
}

const ClaimStatusMappingPage: React.FC = () => {
    // const [searchStr, setSearchStr] = useState('');
    const [initialTableData, setInitialTableData] = useState<ITableData[]>();
    const [selectedDashboardStatusEnum, setSelectedDashboardStatusEnum] = useState<string>();
    const toast = useToast();

    const dashboardStatusEnums = [
        { name: 'Under Progress', value: 'UNDER_PROGRESS' },
        { name: 'Under Progress (query)', value: 'UNDER_PROGRESS_QUERY' },
        { name: 'Processed', value: 'PROCESSED' },
        { name: 'Settled', value: 'SETTLED' },
        { name: 'Repudiated', value: 'REPUDIATED' },
        { name: 'Closed', value: 'CLOSED' }
    ];

    const handleFetchData = () => {
        ClaimStatusMapping.fetchAll().then((res) => {
            setInitialTableData(res.data?.map((itm) => (
                {
                    'TPA claim status (from MIS)': itm.misStatus,
                    'Loop claim status': itm.dashboardStatus == 'Unmapped' ?
                        <Form.Dropdown
                            placeholder="Unmapped (Select Loop claim status)"
                            listItems={dashboardStatusEnums}
                            selectedItem={selectedDashboardStatusEnum ?? 'Unmapped'}
                            onClick={(val: string) => {
                                setSelectedDashboardStatusEnum(val);
                                handleAssignTPAStatus(itm.id, val);
                            }}
                        /> : itm.dashboardStatus,
                    'App Status': itm.appStatus,
                    'Status Tag': itm.statusTag
                }
            )));
        });
    };

    useEffect(() => {
        handleFetchData();
    }, [selectedDashboardStatusEnum]);

    const modalContext = useConfirmationModalContext();

    const handleAssignTPAStatus = async (claimStatusId: number, dashboardStatusEnum: string) => {
        const isConfirmed = await modalContext.showConfirmation({
            title: '',
            message: (
                <StyledModalWrapper>
                    <StyledSendIcon $width="72px" $margin="0px" src={TickIcon} />
                    <StyledTypoWrapperMargin $margin="20px 0px 10px 0px">
                        <Typography variant="large" weight="bold">
                            Are you sure?
                        </Typography>
                    </StyledTypoWrapperMargin>
                    <Typography variant="small">
                        This change is irreversible
                    </Typography>
                </StyledModalWrapper>
            ),
            yesLabel: 'Assign',
            noLabel: 'Cancel'
        });
        if (isConfirmed) {
            ClaimStatusMapping.updateUnmappedById(claimStatusId, dashboardStatusEnum).then(() => {
                showToasterMessage(false, 'Claim status assigned successfully');
                handleFetchData();
            }).catch(() => {
                showToasterMessage(true, '');
            });
        }
    };

    const showToasterMessage = (isError: boolean, message: string) => {
        if (toast && toast.displayToast) {
            toast.displayToast(isError ? 'error' : 'success', isError ?
                message ?? 'Something went wrong' :
                `${message}.`, '');
        }
    };

    return (
        <>
            <StyledHeader>
                <h3>Map TPA status to Loop claim status</h3>
            </StyledHeader>
            <StyledTableContainer>
                <LoopTable
                    headers={['TPA claim status (from MIS)', 'Loop claim status']}
                    data={
                        // filteredTableData ??
                        initialTableData ??
                        []
                    }
                />
            </StyledTableContainer>
        </>
    );
};

export default ClaimStatusMappingPage;
