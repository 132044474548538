import styled, { css } from 'styled-components';
export const StyledInput = styled.input<{ isError: boolean; disabled: boolean; $src: string }>(
    (p) => `
    max-width: 100%;
    width: 100%;
    box-sizing: border-box;
    -webkit-box-sizing:border-box;
    -moz-box-sizing: border-box;
    border: 2px solid ${p.isError ? p.theme.colors.error : p.theme.colors.mutedTransparent};
    color: ${p.isError ? p.theme.colors.error : p.disabled ? p.theme.colors.muted : p.theme.colors.black};
    font-size: ${p.theme.fontSizes.body};
    border-radius: 8px;
    box-shadow: 0px 4px 10px ${p.theme.colors.activeBG};
    padding: 12px 18px;
    background-image: url(${p.$src.length ? p.$src : ''});
    ${
    p.$src.length &&
        css`
            background-repeat: no-repeat;
            background-size: 22px;
            background-position: 10px;
            padding: 12px 18px 12px 40px;
            background-color: ${p.theme.colors.beyondGrey};
        `
}
    &::placeholder {
        color: ${p.isError ? p.theme.colors.error : p.theme.colors.muted};
    }
`
);
