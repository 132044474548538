/* eslint-disable react/prop-types */
import React from 'react';
import { StyledInput } from './styles';
import { IInput } from './types';
const Input = React.forwardRef<HTMLInputElement, IInput>((props, ref) => {
    const { maxLen, onChange, value, errorText, type, imgSrc, isDisabled = false, ...rest } = props as IInput;
    return (
        <StyledInput
            {...rest}
            ref={ref}
            onChange={(e) => {
                if (!(maxLen && e.currentTarget?.value?.length > maxLen)) {
                    onChange(e);
                }
            }}
            onKeyDown={(e) => {
                if (e.key === '-' && type === 'number') {
                    e.preventDefault();
                    e.stopPropagation();
                    return;
                }
            }}
            value={value}
            isError={!!errorText.length}
            maxLength={maxLen ? maxLen : 524288}
            type={type ? type : 'text'}
            $src={imgSrc ?? ''}
            disabled={isDisabled}
        />
    );
});
Input.displayName = 'Input';

export default Input;
