import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form } from '@loophealth/loop-ui-web-library';
import { EXTERNAL_ROUTES, INTERNAL_ROUTES } from '../../../../../utils/constants/Routes';
import { Table, TableLoading, FloatingPlusButton, TableError, DataTable } from '../../../../atoms';
import { fetchClaims } from '../../../../../redux/slices/ClaimSlice/thunks';
import { resetClaimsState } from '../../../../../redux/slices/ClaimSlice';
import { ReduxState } from '../../../../../redux';
import { useSelector, useDispatch } from 'react-redux';
import { getDataTableColumns } from '../../../../../utils/common/columns';
import { displayToastNotification } from '../../../../../utils/common/common';
import { DEFAULT_PAGE_SIZE, CLAIMS_FILTER_PARAMS, pageSizeOptions } from '../../../../../utils/constants';
import {
    PlusButtonContainer,
    StyledTableContainer,
    StyledTable,
    StyledFilterSection,
    StyledSearchContainer,
    StyledModalWrapper,
    StyledSendIcon,
    StyledFilterWrapper,
    StyledCalendarWrapper,
    StyledDownloadContainer,
    StyledFilterContainer,
    StyledSidebarWrapper,
    StyledTypoWrapperMargin,
    StyledActionableWrapper
} from './styles';
import { updateClaimStatusAPI } from '../../../../../adapters';
import { useToast } from '../../../../../utils/hooks/useToast';
import { SEGMENT_ACTIONS } from '../../../LoginContainer/utils/constants';
import useSegment from '../../../../../utils/hooks/useSegment';
import { ClaimPriority } from '../../../../../utils/constants';
import { updateClaimPriority, assignClaim } from '../../../../../adapters';
import { useConfirmationModalContext } from '../../../../../utils/hooks/modalConfirmationContext';
import { DocIcon, DownloadIcon, SearchIcon, TickIcon } from '../../../../../assets/img';
import ClaimFilters from '../../../../containers/ClaimFilters';
import { LoopButton, LoopRangeDatePicker, LoopToggle, Typography } from '@loophealth/loop-ui-web-library';
import { downloadClaims } from '../../../../../redux/slices/ClaimSlice/thunks';
import { DateRange } from '@loophealth/loop-ui-web-library/dist/types/atoms/RangeDatePicker/types';
import './index.css';
import '@loophealth/loop-ui-web-library/dist/index.css';
import CheckboxComponent from './checkbox';
import SelectedRowsHeader from '../../../../containers/BulkAssignmentModal/selectedRowsHeader';
import { AgentRoles } from '../../../../../redux/slices/UserDataSlice/types';
import { extractClaimDetails } from '../../../../../utils/services/claims';
import { CountResult } from '../../../../../utils/services/claims/types';
import { TextView } from '../../../../atoms/TextView';
import theme from '../../../../../theme';
import { IClaim } from './types';
import { getFormattedFiltersDate } from '../../../../../utils/services/dateService';
import useDebouncedValue from '../../../../../utils/hooks/useDebouncedValue';
import { fetchClaimDetailsById, resetClaimsDetailsState } from '../../../../../redux/slices/ClaimDetailsSlice';
import ClaimDetailsModal from '../../../../containers/ClaimDetailsModal';

const FiltersSidebar = React.lazy(() => import('../../../../containers/FiltersSidebar'));

const ClaimsDirectory: React.FunctionComponent = () => {
    let sortingInProgressRef = false;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const trackClick = useSegment('click');
    const toast = useToast();
    const claimsDataArray = useSelector((state: ReduxState) => state.claimSlice.claimList);
    const checkAgentPermission = useSelector((state: ReduxState) => state.userDataSlice?.userPermissions?.data);
    const options = useSelector((state: ReduxState) => state.claimStatusOptionsSlice?.options);
    const claimStatusOptions = options?.data || [];
    const [currentPage, setCurrentPage] = React.useState<number>(1);
    const [actionableFilter, setActionableFilter] = React.useState<boolean>(true);
    const [selectedRows, setSelectedRows] = useState<string[]>([]);
    const [selectedClaims, setSelectedClaims] = useState<CountResult | []>([]);
    const [selectedClaimsDetails, setSelectedClaimsDetails] = useState<IClaim[]>([]);
    const [pageSize, setPageSize] = React.useState<number>(DEFAULT_PAGE_SIZE);
    const prevSelectedFilters: any = claimsDataArray?.selectedFilters;
    const [searchClaimId, setSearchClaimId] = React.useState(prevSelectedFilters?.search);
    const [isMultiSelectActive, setMultiSelectActive] = React.useState(true);
    const debouncedSearchTerm = useDebouncedValue(searchClaimId, 1000);
    const [claimDetailsIsVisible, setClaimDetailsIsVisible] = React.useState<boolean>(false);
    React.useEffect(() => {
        if (debouncedSearchTerm !== undefined) {
            fetchClaimsList({ search: debouncedSearchTerm });
        }
    }, [debouncedSearchTerm]);

    React.useEffect(() => {
        if (checkAgentPermission) {
            setActionableFilter(
                checkAgentPermission.level === AgentRoles.L1 || checkAgentPermission.level === AgentRoles.L2
            );
        }
    }, [checkAgentPermission?.level]);

    React.useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const queryPageNo = queryParams.get('pageNo');
        const pageNo = queryPageNo ? Number(queryPageNo) : 1;
        setCurrentPage(pageNo);
    }, []);

    const handleAddClaimsClick = (e: React.MouseEvent) => {
        e.preventDefault();
        const segmentAction = SEGMENT_ACTIONS.CLICK.add_claim;
        trackClick({
            name: segmentAction.name,
            properties: {
                description: segmentAction.properties?.description,
                page_title: segmentAction.properties?.page_title,
                login_status: segmentAction.properties?.login_status
            }
        });
        navigate(EXTERNAL_ROUTES.APP + '/' + INTERNAL_ROUTES.UPLOAD_CLAIM.route);
    };

    const navigateToClaim = (id: string) => {
        const segmentAction = SEGMENT_ACTIONS.CLICK.claim_details;
        trackClick({
            name: segmentAction.name,
            properties: {
                description: segmentAction.properties?.description,
                page_title: segmentAction.properties?.page_title,
                login_status: segmentAction.properties?.login_status,
                claim_id: (segmentAction.properties?.claim_id as (claimId: string) => string)(id) || ''
            }
        });
        navigate(EXTERNAL_ROUTES.APP + '/' + INTERNAL_ROUTES.CLAIM_DETAILS.route + '?id=' + id);
    };

    const fetchClaimsList = (data: any = CLAIMS_FILTER_PARAMS): any => {
        dispatch(
            fetchClaims({
                ...prevSelectedFilters,
                ...data,
                page: data?.page ?? 1,
                perPage: data?.pageSize ?? pageSize
            })
        );
    };

    const resetFilters = () => {
        dispatch(resetClaimsState());
        fetchClaimsList();
    };

    const handleDownloadClaims = () => {
        dispatch(downloadClaims());
        if (toast && toast.displayToast) {
            toast.displayToast('success', 'Download request scheduled successfully', '');
        }
    };

    const handleCancelMultiSelect = () => {
        setMultiSelectActive(false);
        setSelectedRows([]);
        setSelectedClaims([]);
        setSelectedClaimsDetails([]);
        setTimeout(() => {
            setMultiSelectActive(true);
        });
    };

    const handleSort = async () => {
        setCurrentPage(1);
        sortingInProgressRef = true;
        setTimeout(() => {
            setCurrentPage(currentPage);
        }, 0);
    };

    const handlePageChange = (page: number) => {
        if (currentPage === page || sortingInProgressRef) {
            return;
        }

        sortingInProgressRef = false;
        const isNext = currentPage < page;
        const pageNo = isNext ? currentPage + 1 : currentPage - 1;
        const newurl = window.location.origin + window.location.pathname + '?pageNo=' + pageNo;
        window.history.pushState({ path: newurl }, '', newurl);

        setCurrentPage(page);
        fetchClaimsList({ page });
    };

    const handleRowsPerPageChange = (pageSize: number) => {
        setPageSize(pageSize);
        fetchClaimsList({ pageSize });
    };

    const handleStatusChange = async (id: string, status: string) => {
        if (!id) return;
        const isConfirmed = await modalContext.showConfirmation({
            title: '',
            message: (
                <StyledModalWrapper>
                    <StyledSendIcon $width="72px" $margin="0px" src={DocIcon} />
                    <StyledTypoWrapperMargin $margin="20px 0px 10px 0px">
                        <Typography variant="large" weight="bold">
                            Are you sure you want to change claim status to ‘{status}’?
                        </Typography>
                    </StyledTypoWrapperMargin>

                    <Typography variant="small">This will change the status to ‘{status}’.</Typography>
                </StyledModalWrapper>
            ),
            yesLabel: 'Change Status',
            noLabel: 'Cancel'
        });
        if (isConfirmed) {
            const result: any = await updateClaimStatusAPI(id, status);
            const segmentAction = SEGMENT_ACTIONS.CLICK.claim_status_update;
            trackClick({
                name: segmentAction.name,
                properties: {
                    description: segmentAction.properties?.description,
                    page_title: segmentAction.properties?.page_title,
                    login_status: segmentAction.properties?.login_status,
                    status_type_selected:
                        (segmentAction.properties?.status_type_selected as (statusSelected: string) => string)(
                            status
                        ) || '',
                    claim_id: (segmentAction.properties?.claim_id as (claimId: string) => string)(id) || ''
                }
            });
            displayToastNotification(result, toast);
        }
    };

    const showToasterMessage = (isError: boolean, message: string) => {
        if (toast && toast.displayToast) {
            toast.displayToast(isError ? 'error' : 'success', isError ? 'Something went wrong' : `${message}.`, '');
        }
    };

    const markClaimVIP = async (claimId: string) => {
        const isConfirmed = await modalContext.showConfirmation({
            title: '',
            message: (
                <StyledModalWrapper>
                    <StyledSendIcon $width="72px" $margin="0px" src={TickIcon} />
                    <StyledTypoWrapperMargin $margin="20px 0px 10px 0px">
                        <Typography variant="large" weight="bold">
                            Are you sure?
                        </Typography>
                    </StyledTypoWrapperMargin>

                    <Typography variant="small">
                        This will mark claim <b>{claimId}</b> as VIP.
                    </Typography>
                </StyledModalWrapper>
            ),
            yesLabel: 'Mark VIP',
            noLabel: 'Cancel'
        });
        if (isConfirmed) {
            const result = await updateClaimPriority(claimId, ClaimPriority.VIP);
            const res = result?.calimId ? true : false;
            if (res) {
                showToasterMessage(false, 'Claim successfully mark as VIP');
            } else {
                showToasterMessage(true, '');
            }
        }
    };

    const modalContext = useConfirmationModalContext();
    const assignClaimToL2 = async (claimId: string) => {
        const isConfirmed = await modalContext.showConfirmation({
            title: '',
            message: (
                <StyledModalWrapper>
                    <StyledSendIcon $width="72px" $margin="0px" src={TickIcon} />
                    <StyledTypoWrapperMargin $margin="20px 0px 10px 0px">
                        <Typography variant="large" weight="bold">
                            Are you sure?
                        </Typography>
                    </StyledTypoWrapperMargin>

                    <Typography variant="small">
                        This will assign claim <b>{claimId}</b> to L2 professional.
                    </Typography>
                </StyledModalWrapper>
            ),
            yesLabel: 'Assign',
            noLabel: 'Cancel'
        });
        if (isConfirmed) {
            const result = await assignClaim(claimId);
            const res = result?.calimId ? true : false;
            if (res) {
                showToasterMessage(false, 'Claim successfully assign to L2');
            } else {
                showToasterMessage(true, '');
            }
        }
    };

    const handleRowSelected = (rows: any) => {
        setMultiSelectActive(true);
        setSelectedRows(rows?.selectedRows?.map((row: any) => row.id));
        const selectedClaims = extractClaimDetails(rows?.selectedRows);
        setSelectedClaims(selectedClaims);
        setSelectedClaimsDetails(rows?.selectedRows);
    };

    const onDateRangeChanged = (range: DateRange) => {
        const { startDate, endDate } = range;
        if (startDate && endDate) {
            const dateFilter = {
                sdate: getFormattedFiltersDate(startDate, 'Start'),
                edate: getFormattedFiltersDate(endDate, 'End')
            };
            fetchClaimsList(dateFilter);
        }
    };

    const onLastDateChanged = (range: DateRange) => {
        const { startDate, endDate } = range;
        if (startDate && endDate) {
            const dateFilter = {
                lusDate: getFormattedFiltersDate(startDate, 'Start'),
                lueDate: getFormattedFiltersDate(endDate, 'End')
            };
            fetchClaimsList(dateFilter);
        }
    };

    const handleActionableClaims = (actionableFilter: boolean) => {
        fetchClaimsList({ actionableClaims: !actionableFilter });
        setActionableFilter(!actionableFilter);
    };

    const onSearchStringChanged = (searchText: React.ChangeEvent<HTMLInputElement>) => {
        searchText.preventDefault();
        setSearchClaimId(searchText.currentTarget.value);
    };
    const handleClaimDetailsClicked = (id: string) => {
        dispatch(resetClaimsDetailsState());
        dispatch(fetchClaimDetailsById(id));
        setClaimDetailsIsVisible(true);
    };
    return (
        <>
            <StyledFilterSection>
                <Typography variant="title2">Claims Directory</Typography>
                <StyledSearchContainer>
                    <Form.Input
                        placeholder="Search by Company name, Employee name, Claim Id, Patient Name or Employee ID"
                        value={searchClaimId}
                        onChange={onSearchStringChanged}
                        iconSrc={SearchIcon}
                        iconAlign="left"
                    />
                </StyledSearchContainer>
                <StyledDownloadContainer>
                    <LoopButton
                        variant="filled"
                        iconSrc={DownloadIcon}
                        iconOrder={'left'}
                        onClick={handleDownloadClaims}
                    >
                        Download
                    </LoopButton>
                </StyledDownloadContainer>
            </StyledFilterSection>

            {checkAgentPermission &&
            (checkAgentPermission?.level === AgentRoles.L1 || checkAgentPermission?.level === AgentRoles.L2) ? (
                    <StyledFilterWrapper>
                        <LoopToggle
                            defaultState={actionableFilter}
                            onClick={() => handleActionableClaims(actionableFilter)}
                        />
                        <StyledActionableWrapper>
                            <Typography variant="medium">
                            My claims due today ({claimsDataArray.data ? claimsDataArray.data.totalCount : '...'})
                            </Typography>
                        </StyledActionableWrapper>
                    </StyledFilterWrapper>
                ) : (
                    <></>
                )}

            {!actionableFilter || checkAgentPermission === null ? (
                <StyledFilterWrapper>
                    <StyledCalendarWrapper>
                        <TextView
                            fontSize="14px"
                            margin={'0px 0px 10px 0px'}
                            fontWeight="bold"
                            color={theme.colors.beyondGrey}
                        >
                            Date Filter
                        </TextView>
                        <LoopRangeDatePicker
                            onChange={onDateRangeChanged}
                            maxDate={new Date()}
                            startDate={prevSelectedFilters?.sdate}
                            endDate={prevSelectedFilters?.edate}
                        />
                    </StyledCalendarWrapper>
                    <StyledFilterContainer>
                        <ClaimFilters
                            fetchClaimsList={fetchClaimsList}
                            prevSelectedFilters={prevSelectedFilters}
                            resetFilters={resetFilters}
                        />
                    </StyledFilterContainer>
                    <StyledCalendarWrapper>
                        <TextView
                            fontSize="14px"
                            margin={'0px 0px 10px 0px'}
                            fontWeight="bold"
                            color={theme.colors.beyondGrey}
                        >
                            Claim Last Update Date
                        </TextView>
                        <LoopRangeDatePicker
                            onChange={onLastDateChanged}
                            maxDate={new Date()}
                            startDate={prevSelectedFilters?.lusDate}
                            endDate={prevSelectedFilters?.lueDate}
                        />
                    </StyledCalendarWrapper>
                    <StyledSidebarWrapper>
                        <FiltersSidebar
                            fetchClaimsList={fetchClaimsList}
                            prevSelectedFilters={prevSelectedFilters}
                            resetFilters={resetFilters}
                        />
                    </StyledSidebarWrapper>
                </StyledFilterWrapper>
            ) : null}

            <StyledTableContainer>
                <StyledTable>
                    <Table>
                        <DataTable
                            columns={getDataTableColumns(
                                navigateToClaim,
                                handleStatusChange,
                                markClaimVIP,
                                assignClaimToL2,
                                handleClaimDetailsClicked,
                                claimStatusOptions
                            )}
                            data={claimsDataArray?.data?.claims || []}
                            progressPending={claimsDataArray.loading}
                            progressComponent={
                                <>
                                    <TableLoading />
                                </>
                            }
                            persistTableHead
                            highlightOnHover
                            pointerOnHover
                            paginationServer={true}
                            onChangePage={handlePageChange}
                            currentPage={currentPage}
                            paginationPerPage={DEFAULT_PAGE_SIZE}
                            paginationDefaultPage={currentPage}
                            selectableRows={checkAgentPermission?.level === AgentRoles.Manager}
                            selectableRowsComponent={CheckboxComponent}
                            onSelectedRowsChange={handleRowSelected}
                            paginationTotalRows={claimsDataArray?.data?.totalCount}
                            onChangeRowsPerPage={handleRowsPerPageChange}
                            paginationRowsPerPageOptions={pageSizeOptions}
                            onSort={handleSort}
                            clearSelectedRows={!isMultiSelectActive}
                        />
                        {claimsDataArray.error && !claimsDataArray.loading && (
                            <TableError>Something went wrong!</TableError>
                        )}
                    </Table>
                </StyledTable>
                {!!selectedRows.length && (
                    <SelectedRowsHeader
                        selectedRowCount={selectedRows.length}
                        selectedClaims={selectedRows}
                        handleCancel={handleCancelMultiSelect}
                        selectedClaimsList={selectedClaims}
                        selectedClaimsDetails={selectedClaimsDetails}
                    />
                )}
                <ClaimDetailsModal isVisible={claimDetailsIsVisible} setIsVisible={setClaimDetailsIsVisible} />
            </StyledTableContainer>
            <PlusButtonContainer>
                <FloatingPlusButton onClick={handleAddClaimsClick} />
            </PlusButtonContainer>
        </>
    );
};
export default ClaimsDirectory;
