import styled from 'styled-components';

export const StyledForm = styled.div<{
    $fullWidth: boolean;
}>`
    margin-bottom: 10px;
    max-width: 40%;
    min-width: ${(p) => (p.$fullWidth ? `90%` : `40%`)};
    @media (max-width: 768px) {
        min-width: 100%;
        margin-right: 0px;
    }
    .leadpage-input {
        width: 100%;
        box-shadow: 0px 4px 10px rgba(149, 159, 190, 0.1);
        border-radius: 8px;
        padding: 9px;
        box-sizing: border-box;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        color: #3d4144;
        background: #edeff3;
        border: 0px;
    }
    .react-date-picker__wrapper {
        border: 0;
    }
    .date-picker-error {
        border: 1px solid ${(p) => p.theme.colors.error};
    }
`;

export const StyledErrorContainer = styled.div`
    margin-top: 2px;
`;
