import { useEffect, useState } from 'react';

/**
 * A hook to get a debounced value.
 *
 * @template T
 * @param {T} value - The value to be debounced.
 * @param {number} delay - The delay in milliseconds for debouncing.
 * @return {T} The debounced value.
 */
function useDebouncedValue<T>(value: T, delay: number): T {
    const [debouncedValue, setDebouncedValue] = useState<T>(value);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);

        return () => clearTimeout(timeoutId);
    }, [value, delay]);

    return debouncedValue;
}

export default useDebouncedValue;
