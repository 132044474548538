import React from 'react';
import firebase from 'firebase';
import ReactDOM from 'react-dom';
import { Provider as ReduxProvider } from 'react-redux';
import { store } from './redux';
import { ThemeProvider } from 'styled-components';
import theme from './theme';
import App from './App';
import './index.css';
import { BrowserRouter } from 'react-router-dom';
import { initializeSegment } from './segment';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
declare global {
    interface Window {
        recaptchaVerifier: firebase.auth.RecaptchaVerifier;
        confirmationResult: firebase.auth.ConfirmationResult | null;
        analytics: any;
    }
}
if (process.env.NODE_ENV === 'production') {
    Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_URL,
        integrations: [new BrowserTracing()],
        tracesSampleRate: 1.0,
        environment: process.env.NODE_ENV
    });
}
initializeSegment();

ReactDOM.render(
    <BrowserRouter>
        <ThemeProvider theme={theme}>
            <ReduxProvider store={store}>
                <App />
            </ReduxProvider>
        </ThemeProvider>
    </BrowserRouter>,
    document.getElementById('root')
);
