import React from 'react';
import {
    StyledContainer,
    StyledLogo,
    StyledMenuContainer,
    StyledLogoutIcon,
    StyledLogoutContainer,
    StyledModalWrapper,
    StyledSendIcon,
    StyledNavbar,
    StyledNavLink
} from './styles';
import { DownArrowIcon, InfoIcon, LoopLogo } from '../../../assets/img';
import { IHeader } from './types';
import { NameIcon } from '../../atoms';
import { useSelector } from 'react-redux';
import { ReduxState } from '../../../redux';
import theme from '../../../theme';
import { capitalizeFirstLetter } from '../../../utils/common/common';
import { useConfirmationModalContext } from '../../../utils/hooks/modalConfirmationContext';
import { TextView } from '../../atoms/TextView';

const Header: React.FunctionComponent<IHeader> = ({ signout, goHome }) => {
    const userName = useSelector((state: ReduxState) => state.user.userData.data);

    const [isLogoutBtnVisible, setIsLogoutBtnVisible] = React.useState(false);
    const logoutRef = React.useRef<HTMLDivElement | null>(null);

    React.useEffect(() => {
        const checkIfClickedOutside = (e: { target: any }) => {
            if (isLogoutBtnVisible && logoutRef?.current && !logoutRef?.current?.contains(e.target)) {
                setIsLogoutBtnVisible(false);
            }
        };
        document.addEventListener('mousedown', checkIfClickedOutside);
        return () => {
            document.removeEventListener('mousedown', checkIfClickedOutside);
        };
    }, [isLogoutBtnVisible]);

    const modalContext = useConfirmationModalContext();
    const handleSignoutClick = async () => {
        const isConfirmed = await modalContext.showConfirmation({
            title: '',
            message: (
                <StyledModalWrapper>
                    <StyledSendIcon $width="72px" $margin="0px" src={InfoIcon} />
                    <TextView
                        fontSize="18px"
                        color={theme.colors.beyondGrey}
                        fontWeight="600"
                        margin="20px 0px 10px 0px"
                        textAlign="center"
                    >
                        Are you sure you want to sign out?
                    </TextView>
                    <TextView fontSize="14px" fontWeight="400" color={theme.colors.beyondGrey} textAlign="center">
                        This will log you out of the claims dashboard
                    </TextView>
                </StyledModalWrapper>
            ),
            yesLabel: 'Sign out',
            noLabel: 'Cancel'
        });
        if (isConfirmed) {
            setIsLogoutBtnVisible(false);
            localStorage.clear();
            signout();
        }
    };

    return (
        <StyledContainer>
            <StyledLogo src={LoopLogo} alt={'Loop Health Logo'} onClick={goHome} />
            <StyledNavbar>
                <StyledNavLink to='/app/claim-status-mapping'>
                    Status mapping
                </StyledNavLink>
            </StyledNavbar>
            {userName ? (
                <StyledMenuContainer ref={logoutRef}>
                    <NameIcon name={userName?.firstName} color={theme.colors.loopEmerald} />{' '}
                    <TextView fontSize="18px" margin="0px 15px 0px 10px" color={theme.colors.beyondGrey}>
                        {capitalizeFirstLetter(userName?.firstName) + ' ' + capitalizeFirstLetter(userName?.lastName)}
                    </TextView>
                    <StyledLogoutIcon
                        $rotate={isLogoutBtnVisible ? true : false}
                        src={DownArrowIcon}
                        onClick={() => setIsLogoutBtnVisible(!isLogoutBtnVisible)}
                    ></StyledLogoutIcon>
                    {isLogoutBtnVisible ? (
                        <StyledLogoutContainer onClick={handleSignoutClick}>
                            <TextView fontSize="14px" margin="0px 0px 0px 15px" color={theme.colors.beyondGrey}>
                                Logout
                            </TextView>
                        </StyledLogoutContainer>
                    ) : null}
                </StyledMenuContainer>
            ) : null}
        </StyledContainer>
    );
};

export default Header;
