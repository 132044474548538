export const initialState: any = {
    claimDetails: {
        data: null,
        error: null,
        loading: false
    },
    remarks: {
        data: null,
        error: null,
        loading: false
    },
    addRemarkState: {
        data: null,
        error: null,
        loading: false
    },
    claimPriorityState: {
        data: null,
        error: null,
        loading: false
    }
};
