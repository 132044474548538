import React, { useState } from 'react';
import { StyledFilterContainer, StyledFilterWrapper } from './styles';
import { IFilters } from '../../../redux/slices/ClaimSlice/types';
import Select from 'react-select';
import { ReduxState } from '../../../redux';
import { useSelector } from 'react-redux';
import { Form } from '@loophealth/loop-ui-web-library';
import theme from '../../../theme';
import { TextView } from '../../atoms/TextView';
import { FilterType } from '../../../redux/slices/ClaimSlice/types';
const ClaimFilters: React.FunctionComponent<IFilters> = ({ fetchClaimsList, prevSelectedFilters }) => {
    const [selectedFilters, setSelectedFilters] = useState(prevSelectedFilters as any);
    const filtersData = useSelector((state: ReduxState) => state.FiltersSlice.filters);

    React.useEffect(() => {
        setSelectedFilters(prevSelectedFilters);
    }, [prevSelectedFilters]);
    const textViewProps = {
        fontSize: '13px',
        lineHeight: '18px',
        color: theme.colors.beyondGrey
    };

    const handleOnChange = (e: any, filterKey: string) => {
        const obj: Record<string, string[] | boolean> = {};

        if (Array.isArray(e)) {
            const selectedValues = e.map((row) => row.id);

            // if (selectedValues.includes('no_action_taken')) {
            //     obj['no_action_taken'] = true;
            //     // selectedValues = selectedValues.filter((value) => value !== 'no_action_taken');
            // } else {
            //     obj['no_action_taken'] = false;
            // }

            obj[filterKey] = selectedValues;
        } else {
            obj[filterKey] = [];
        }

        setSelectedFilters({
            ...selectedFilters,
            ...obj
        });

        fetchClaimsList({
            ...selectedFilters,
            ...obj
        });
    };

    return (
        <StyledFilterWrapper>
            {filtersData?.data
                ?.filter((row: any) => row.position != 'sidebar')
                ?.map((filter: any, index: number) => {
                    if (filter.type === FilterType.MULTISELECT) {
                        return (
                            <StyledFilterContainer key={index}>
                                <TextView {...textViewProps}>{filter.name}</TextView>
                                <Select
                                    defaultValue={
                                        selectedFilters[filter.key]?.length &&
                                        filter.values.filter((row: any) => {
                                            return selectedFilters[filter.key].includes(row.id);
                                        })
                                    }
                                    onChange={(e) => handleOnChange(e, filter.key)}
                                    placeholder={filter.name}
                                    options={filter.values}
                                    isMulti={true}
                                />
                            </StyledFilterContainer>
                        );
                    } else {
                        return (
                            <StyledFilterContainer key={index}>
                                <TextView {...textViewProps}>{filter.name}</TextView>
                                <Form.Dropdown
                                    selectedItem={selectedFilters[filter.key]}
                                    placeholder={`Select ${filter.key}`}
                                    onClick={(e: string) => {
                                        const obj: Record<string, string> = {};
                                        obj[filter.key] = e;
                                        setSelectedFilters({ ...selectedFilters, ...obj });
                                    }}
                                    listItems={filter.values}
                                />
                            </StyledFilterContainer>
                        );
                    }
                })}
        </StyledFilterWrapper>
    );
};

export default ClaimFilters;
