import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchAllClaimAgentsAPI } from '../../../adapters';

const CLAIM_AGENT_SLICE = {
    FETCH_CLAIM_AGENT: 'FETCH_CLAIM_AGENT'
};

export const fetchAllClaimAgents = createAsyncThunk(CLAIM_AGENT_SLICE.FETCH_CLAIM_AGENT, async () => {
    try {
        const response = await fetchAllClaimAgentsAPI();
        return {
            claimAgentsData: response
        };
    } catch (e) {
        throw e;
    }
});
