import axios, { AxiosResponse } from 'axios';
import { IResponse } from './types';

export const loopHTTPClient = axios.create({
    baseURL: process.env.REACT_APP_FIREBASE_FUNCTION_BASE_URL,
    headers: {
        'Content-Type': 'application/json',
        'loop-source': process.env.REACT_APP_NAME ?? ''
    }
});

const processResponse = (response: AxiosResponse) => response.data;

export const get = <T>(url: string, params?: Record<string, unknown>): Promise<IResponse<T>> =>
    loopHTTPClient.get(url, { params }).then(processResponse);
export const post = <T>(url: string, body: Record<string, unknown>): Promise<IResponse<T>> =>
    loopHTTPClient.post(url, body).then(processResponse);
export const patch = <T>(url: string, body: Record<string, unknown>): Promise<IResponse<T>> =>
    loopHTTPClient.patch(url, body).then(processResponse);
export const put = <T>(url: string, body: Record<string, unknown>): Promise<IResponse<T>> =>
    loopHTTPClient.put(url, body).then(processResponse);
