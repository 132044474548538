import styled from 'styled-components';

export const StyledWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
`;

export const StyledContainer = styled.div`
    max-width: 1000px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
`;
export const StyledTitleContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
`;

export const StyledTitle = styled.h3`
    text-transform: uppercase;
    letter-spacing: 0.8px;
    font-size: ${(p) => p.theme.fontSizes.title};
`;
export const StyledSubTitle = styled.span(
    (p) => `
    color: ${p.theme.colors.black};
    font-size: ${p.theme.fontSizes.body};
    margin: 15px 0px;
`
);

export const StyledCloseIcon = styled.img`
    max-width: 36px;
    height: auto;
    cursor: pointer;
`;
