import React from 'react';
import { ClaimsDirectoryIcon } from '../../assets/img';

export interface IRouteIconComponent {
    isActive?: boolean;
}

import { ClaimsDirectory, ClaimsDetails } from '../../components/pages/SPAContainer/pages';
import ClaimStatusMapping from '../../components/pages/SPAContainer/pages/ClaimStatusMapping';
import UploadClaim from '../../components/pages/SPAContainer/pages/UploadClaim';

export const EXTERNAL_ROUTES = {
    LOGIN: '/login',
    APP: '/app'
};

export type IRouteName = 'CLAIM_DIRECTORY' | 'CLAIM_DETAILS' | 'UPLOAD_CLAIM' | 'CLAIM_STATUS_MAPPING';

export const INTERNAL_ROUTES: Record<
    IRouteName,
    {
        route: string;
        name: string;
        Component: React.FunctionComponent;
        isVisible: boolean;
        icon?: string;
        IconComponent?: React.FunctionComponent<IRouteIconComponent>;
    }
> = {
    CLAIM_DIRECTORY: {
        route: 'claims-directory',
        name: 'Claims Directory',
        Component: ClaimsDirectory,
        isVisible: true,
        icon: ClaimsDirectoryIcon
    },
    UPLOAD_CLAIM: {
        route: 'upload-claim',
        name: 'Upload Claim',
        Component: UploadClaim,
        isVisible: false,
        icon: ''
    },
    CLAIM_DETAILS: {
        route: 'claim-details',
        name: 'Claim Details',
        Component: ClaimsDetails,
        isVisible: false,
        icon: ''
    },
    CLAIM_STATUS_MAPPING: {
        route: 'claim-status-mapping',
        name: 'Claim Status Mapping',
        Component: ClaimStatusMapping,
        isVisible: false,
        icon: ''
    }
};
