import styled from 'styled-components';
import { SearchIcon } from '../../../../../assets/img';

export const StyledTable = styled.div`
    overflow-y: scroll;
    &::-webkit-scrollbar {
        display: none;
    }
`;

export const PlusButtonContainer = styled.div``;
export const StyledTableContainer = styled.div`
    width: 100%;
    padding-bottom: 20px;
`;

export const StyledTitle = styled.h3`
    text-transform: uppercase;
    letter-spacing: 0.8px;
    font-size: ${(p) => p.theme.fontSizes.title};
    min-width: 350px;
    display: flex;
`;

export const StyledFilterSection = styled.div`
    display: flex;
    padding: 30px 0px;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    background-color: ${(p) => p.theme.colors.white};
    margin-bottom: 20px;
`;

export const StyledSearchContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 700px;
    flex-wrap: wrap;
    > div {
        width: inherit;
    }
    @media (max-width: 1024px) {
        width: 100%;
        gap: 10px;
    }
`;

export const StyledInput = styled.input`
    border: none;
    width: 650px;
    border-radius: 8px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.2px;
    color: ${(p) => p.theme.colors.beyondGrey};
    font-size: ${(p) => p.theme.fontSizes.label};
    padding: 9px 18px 9px 50px;
    background: #f0f0f0 url(${SearchIcon}) no-repeat 20px center;
    background-size: 18px 18px;
`;

export const StyledModalWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 20px;
    align-items: center;
    text-align: center;
`;

export const StyledSendIcon = styled.img<{
    $width?: string;
    $margin?: string;
}>`
    max-height: ${(p) => p.$width ?? '24px'};
    margin-left: ${(p) => p.$margin ?? '10px'};
`;

export const StyledFilterWrapper = styled.div`
    display: flex;
    padding: 20px;
`;

export const StyledCalendarWrapper = styled.div`
    display: flex;
    margin-right: 5px;
    flex-direction: column;
`;
export const StyledFilterContainer = styled.div`
    display: flex;
    width: 70%;
`;
export const StyledSidebarWrapper = styled.div`
    display: flex;
    width: 12%;
    margin-left: 10px;
`;

export const StyledDownloadContainer = styled.div`
    display: flex;
`;

export const StyledCheckboxInput = styled.input.attrs({ type: 'checkbox' })`
    width: 20px;
    height: 20px;
    border-radius: 4px;
    margin-right: 15px;
    cursor: pointer;
    accent-color: ${(p) => p.theme.colors.loopEmerald};
    &::checked {
        background-color: ${(p) => p.theme.colors.loopEmerald};
    }
`;

export const StyledTypoWrapperMargin = styled.div<{
    $margin: string;
}>`
    display: flex;
    margin: ${(p) => p.$margin};
`;

export const StyledActionableWrapper = styled.div`
    display: flex;
    margin-left: 12px;
`;
