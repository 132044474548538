import { createSlice } from '@reduxjs/toolkit';
import { prepareClaimsStatusOptions } from './helper';
import { initialState } from './initialState';
import { fetchClaimStatusOptions } from './thunks';

const ClaimStatusSliceOptions = createSlice({
    name: 'ClaimStatusSliceOptions',
    initialState,
    reducers: {
        resetClaimStatusOptions(state) {
            state.options = initialState.options;
        }
    },
    extraReducers: {
        [fetchClaimStatusOptions.pending as any]: (state) => {
            state.options.loading = true;
        },
        [fetchClaimStatusOptions.rejected as any]: (state, { error }) => {
            state.options.error = error;
            state.options.loading = false;
            state.options.data = [];
        },
        [fetchClaimStatusOptions.fulfilled as any]: (state, { payload }) => {
            const data = prepareClaimsStatusOptions(payload);
            state.options.data = data;
            state.options.error = null;
            state.options.loading = false;
        },
        'common/cleanup': (state) => {
            state.options = initialState.options;
        }
    }
});
export default ClaimStatusSliceOptions.reducer;
export const { resetClaimStatusOptions } = ClaimStatusSliceOptions.actions;
export { fetchClaimStatusOptions };
