import styled from 'styled-components';

export const StyledContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 100%;
    width: 100%;
`;
export const StyledTitle = styled.h2(
    (p) => `
    font-size: ${p.theme.fontSizes.landing};
    color: ${p.theme.colors.black};
    margin: 0;
    margin-bottom: 8px;
    font-style: normal;
    font-weight: 600;
    line-height: 40px;
    font-kerning: none;
`
);
export const StyledHeading = styled.p(
    (p) => `
    font-size: ${p.theme.fontSizes.heading};
    color: ${p.theme.colors.beyondGrey};
    margin: 0px;
    font-style: normal;
    font-weight: 400;
`
);
export const StyledOAuthContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    margin-top: 50px;
    width: 100%;
    @media (max-width: 768px) {
        max-width: 100%;
    }
`;
