import { customAlphabet } from 'nanoid';
import { utils, write } from 'xlsx';
import { EXTERNAL_ROUTES, INTERNAL_ROUTES } from '../constants/Routes';
import { IClaimPriorities } from '../../redux/slices/ClaimSlice/types';
import { IToastContext } from '../hooks/useToast';
export const createLoopId = (prefix: string): string => {
    const nanoid = customAlphabet('1234567890abcdefghijklmnopqrstuvwxyz', 6);
    const documentId = `${prefix}-` + nanoid().toLocaleUpperCase();
    return documentId;
};
export const isValidDate = (date: unknown): boolean => date instanceof Date && !isNaN(date as any);

export const jsonToExcel = (obj: Record<string, unknown>[]): string => {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const ws = utils.json_to_sheet(obj);
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    return URL.createObjectURL(data);
};
export const excelDownload = (data: Record<string, unknown>[], fileName: string): void => {
    const fileLink = jsonToExcel(data);
    const link = document.createElement('a');
    link.href = fileLink;
    link.download = fileName;
    link.click();
    link.remove();
};

export const capitalizeFirstLetter = (name: string): string => {
    return name.charAt(0).toUpperCase() + name.slice(1);
};
export const capitalizeFirstLetterForEveryWord = (text: string): string =>
    text
        .split(' ')
        .map((t) => capitalizeFirstLetter(t.toLowerCase()))
        .join(' ');
export const getSegmentPageName = (pathName: string): string => {
    switch (pathName) {
    case EXTERNAL_ROUTES.LOGIN:
        return 'LOGIN_PAGE';
    case EXTERNAL_ROUTES.APP:
    case INTERNAL_ROUTES.CLAIM_DIRECTORY.route:
        return 'CLAIM_DIRECTORY';
    case INTERNAL_ROUTES.CLAIM_DETAILS.route:
        return 'CLAIM_DETAILS';
    default:
        return 'UNKNOWN_PAGE';
    }
};

export const isClaimPriorityExist = (claimPriorities: IClaimPriorities[], label: string): any => {
    if (!claimPriorities || !claimPriorities.length) return;

    return claimPriorities.find((cp: any) => cp['label'] === label);
};

export const prepareResponse = (response: Record<string, unknown>): any => {
    if (response?.isError) {
        return response;
    }
    return response?.data;
};

export const displayToastNotification = (
    result: Record<string, unknown> | null,
    toast: IToastContext | null,
    additionalError = false
): void => {
    if (result && toast && toast.displayToast) {
        const isError = result?.isError || additionalError ? true : false;
        const errorMessage = (result?.message as string) || 'Something went wrong';
        toast.displayToast(isError ? 'error' : 'success', isError ? errorMessage : 'Claims successfully updated.', '');
    }
};
