import { IAdapterData, IError, ITimestamp } from '../../types';

export interface IUserSlice {
    UserByPhoneOrMobileDetails: IUserInfo;
    userPermissions: IUserRoleInfo;
}

export interface IUserInfo extends IAdapterData {
    data: IUserData | null;
    loading: boolean;
    error: IError | null;
}

export interface IUserRoleInfo extends IAdapterData {
    data: IUserRoleData | null;
    loading: boolean;
    error: IError | null;
}

export interface IUserData {
    userId: string;
    employeeId: string;
    firstName: string;
    lastName: string;
    dob: number;
    email: string;
    mobile: string;
    relationship: IRelationship;
    employer: string;
    gender: IGender;
    createdAt: ITimestamp;
    updatedAt: ITimestamp;
    active: boolean;
}
type IRelationship = 'self' | 'spouse' | 'child' | 'parent' | 'parent-in-law';
type IGender = 'male' | 'female';

export interface IUserRoleData {
    id: string;
    name: string;
    level: string;
    email: string;
    ticketAgentId?: string;
    claimCount?: number;
    isActive?: boolean;
    updatedAt?: string;
    createdAt?: string;
}

export enum AgentRoles {
    Manager = 'Manager',
    L1 = 'L1',
    L2 = 'L2'
}
