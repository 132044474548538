import React, { ForwardedRef, forwardRef } from 'react';
import { StyledCheckboxInput } from './styles';

interface CheckboxProps {
    checked: boolean;
    onClick: React.MouseEventHandler<HTMLInputElement>;
}
const CheckboxComponent = forwardRef(function Checkbox(
    { checked, onClick }: CheckboxProps,
    ref: ForwardedRef<HTMLInputElement>
) {
    return <StyledCheckboxInput type="checkbox" checked={checked} onClick={onClick} ref={ref} />;
});

export default CheckboxComponent;
