interface IRow {
    id: string;
    lable?: string;
    name?: string;
}

const tpaFilter = {
    name: 'TPA name',
    type: 'multiselect',
    key: 'tpaId',
    position: 'sidebar',
    values: []
};

export const prepareFilters = (filters: IRow[]): any => {
    const values = filters?.map((row: any) => {
        return { ...row, label: row.name, value: row.id };
    });
    return { ...tpaFilter, values };
};
