interface IRow {
    id: string;
    lable?: string;
    name?: string;
}

export const prepareClaimsStatusOptions = (data: IRow[] | null): any => {
    return data?.map((row) => {
        return { ...row, value: row.id };
    });
};
