import React, { Suspense } from 'react';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { SPAContainer } from '.';
import firebase from 'firebase';
import { EXTERNAL_ROUTES, INTERNAL_ROUTES, IRouteName } from '../../utils/constants/Routes';
import { Loader, LoginPanel } from '../atoms';
import LoginContainer from './LoginContainer';
import { authenticateUser, getUserDetails } from './LoginContainer/redux/slices/UserSlice';
import { useDispatch } from 'react-redux';
import { SEGMENT_ACTIONS } from './LoginContainer/utils/constants';
import { getSegmentPageName } from '../../utils/common/common';
import useSegment from '../../utils/hooks/useSegment';

const MainRouter: React.FunctionComponent = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const trackPage = useSegment('page');
    React.useEffect(() => {
        firebase.auth().onAuthStateChanged((user) => {
            const email = user?.email;
            if (!user || !email) {
                // handle signout
                // unregistered user was getting authenticated technically by firebase,
                // but the error was not persistent on screen as state was reset on logout.
                // Hence no need of logout for unregistered user.
                navigate(EXTERNAL_ROUTES.LOGIN);
            } else {
                dispatch(authenticateUser());
                dispatch(getUserDetails({ key: 'email', value: email }));
            }
        });
    }, []);
    React.useEffect(() => {
        const currentPageAction =
            SEGMENT_ACTIONS.PAGE[getSegmentPageName(location.pathname?.replace('/app/', '')) as any];
        if (currentPageAction) {
            trackPage({
                name: currentPageAction.name,
                properties: {
                    page_url:
                        (currentPageAction.properties?.page_url as (path: string) => string)(location.pathname) || '',
                    page_id:
                        (currentPageAction.properties?.page_id as (path: string) => string)(location.pathname) || '',
                    login_status: currentPageAction.properties?.login_status || false
                }
            });
        }
    }, [location.pathname]);
    return (
        <Suspense fallback={<Loader />}>
            <Routes>
                <Route index element={<Navigate to={EXTERNAL_ROUTES.APP} />} />
                <Route
                    path={EXTERNAL_ROUTES.LOGIN}
                    element={
                        <LoginContainer
                            title={'Get Started'}
                            subTitle={'Login to view Claim details'}
                            PanelComponent={LoginPanel}
                            redirectTo={() => navigate(EXTERNAL_ROUTES.APP)}
                        />
                    }
                >
                    <Route path="*" element={<Navigate to={EXTERNAL_ROUTES.LOGIN} />} />
                </Route>
                <Route path={EXTERNAL_ROUTES.APP} element={<SPAContainer />}>
                    {/* Default Route */}
                    <Route index element={<INTERNAL_ROUTES.CLAIM_DIRECTORY.Component />} />
                    {/* Map of all other routes */}
                    {Object.keys(INTERNAL_ROUTES).map((internalRoute, index) => {
                        const Page = INTERNAL_ROUTES[(internalRoute as unknown) as IRouteName].Component;
                        return (
                            <Route
                                key={index}
                                path={INTERNAL_ROUTES[(internalRoute as unknown) as IRouteName].route}
                                element={<Page />}
                            />
                        );
                    })}
                    {/* Unknown route -> redirect */}
                    <Route path="*" element={<Navigate to={INTERNAL_ROUTES.CLAIM_DIRECTORY.route} />} />
                </Route>
                <Route path="/" element={<Navigate to={EXTERNAL_ROUTES.APP} />} />
                {/* Need to handle 404 page here */}
                <Route path="*" element={<Navigate to={EXTERNAL_ROUTES.APP} />} />
            </Routes>
        </Suspense>
    );
};

export default MainRouter;
