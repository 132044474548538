import styled from 'styled-components';

export const StyledModalContainer = styled.div`
    display: flex;
    height: 400px;
    width: 100%;
    justify-content: center;
`;

export const StyledAssignModalWrapper = styled.div<{
    $display: boolean;
}>`
    margin: ${(p) => (p.$display ? 'flex' : 'none')};
`;
export const StyledButtonContainer = styled.div`
    display: flex;
`;

export const StyledContainerWrapper = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
`;
