import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchClaimTpaDetailsAPI } from '../../../adapters';
import { ITpaStatus } from './types';

const CLAIM_SLICE_ACTION_TYPES = {
    FETCH_CLAIM_TPA_DETAILS: 'FETCH_CLAIM_TPA_DETAILS'
};

export const fetchClaimTpaDetails = createAsyncThunk(
    CLAIM_SLICE_ACTION_TYPES.FETCH_CLAIM_TPA_DETAILS,
    async (data: ITpaStatus) => {
        const res = await fetchClaimTpaDetailsAPI(data);
        return res;
    }
);
