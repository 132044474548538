/* eslint-disable */
import firebase from 'firebase';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/analytics';
import 'firebase/functions';
import useToken from '../utils/hooks/useToken';
import { ISuccessResponse } from './types';

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

const authToken = useToken();
const FirebaseProvider = firebase.initializeApp(firebaseConfig);
export const Firestore = FirebaseProvider.firestore();
export const FirebaseStorage = FirebaseProvider.storage();
export const FirebaseFunctions = FirebaseProvider.functions('asia-south1');
export const FirebaseCaptcha = firebase.auth;
export const FirebaseAuth = firebase.auth();
export const FirebaseTimestamp = firebase.firestore.Timestamp;
export const FirebaseFieldValue = firebase.firestore.FieldValue;
export default FirebaseProvider;

export const getContentType = (res: any): string => {
    const isJSON = res.headers.get('Content-Type')?.startsWith('application/json') || false;

    if (isJSON) {
        return 'JSON';
    }

    const isText = res.headers.get('Content-Type')?.startsWith('text') || false;
    if (isText) {
        return 'Text';
    }

    return 'Unsupported';
};

export const processResponse = async (res: any) => {
    const contentType = getContentType(res);

    if (res.ok) {
        if (contentType === 'JSON') {
            return await res.json();
        } else {
            return res;
        }
    }
};

type IMethod = 'DELETE' | 'PUT' | 'POST' | 'GET' | 'PATCH';

export const accessAPI = async (
    method: IMethod,
    url: string,
    body?: Record<string, unknown>
): Promise<Record<string, unknown>> => {
    try {
        let baseUrl = process.env.REACT_APP_FIREBASE_FUNCTION_BASE_URL;
        const token = await authToken.generateToken();
        if (
            method === 'GET' &&
            url === '/company' &&
            process.env.REACT_APP_FIREBASE_PROJECT_ID === 'loophealth-patient-dev'
        ) {
            baseUrl = 'https://api-dev.loophealth.com';
        }
        const result = await fetch(baseUrl + url, {
            method: method,
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
                'loop-source': process.env.REACT_APP_NAME ?? ''
            }
        });
        if (result.ok) {
            return await processResponse(result);
        } else {
            const response = await result.json();
            const errorMessage = response?.message;

            if (authToken.tokenIsExpired(errorMessage)) {
                try {
                    const isTokenAvailable = await authToken.generateToken();
                    if (isTokenAvailable) {
                        return await accessAPI(method, url, body);
                    }
                } catch (e) {
                    console.log(e);
                }
            }
            // return { isError: true, ...response };
            if (Array.isArray(errorMessage)) {
                throw new Error(JSON.stringify(errorMessage));
            }
            throw new Error(errorMessage);
        }
    } catch (e) {
        throw e;
    }
};

export const get = async (url: string): Promise<Record<string, unknown> | ISuccessResponse> => {
    try {
        return await accessAPI('GET', url);
    } catch (e) {
        throw e;
    }
};

export const post = async (url: string, body: Record<string, unknown>): Promise<Record<string, unknown>> => {
    try {
        return await accessAPI('POST', url, body);
    } catch (e) {
        throw e;
    }
};

export const put = async (url: string, body: Record<string, unknown>): Promise<Record<string, unknown>> => {
    try {
        return await accessAPI('PUT', url, body);
    } catch (e) {
        throw e;
    }
};

export const patch = async (url: string): Promise<Record<string, unknown>> => {
    try {
        return await accessAPI('PATCH', url);
    } catch (e) {
        throw e;
    }
};
