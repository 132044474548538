import React from 'react';
import { createPortal } from 'react-dom';
import {
    StyledModalOverlay,
    StyledAbsoluteCloseIcon,
    StyledCloseIcon,
    StyledModalContainer,
    StyledModalContent,
    StyledTitle,
    StyledTitleContainer
} from './styles';
import { CloseIcon } from '../../../assets/img';
import { IModal } from './types';

const Modal: React.FunctionComponent<IModal> = ({ children, isVisible, setIsVisible, title = '' }) => {
    const modalRef = React.useRef<HTMLDivElement | null>(null);
    React.useEffect(() => {
        if (modalRef.current) {
            const rootElem = document.createElement('div');
            rootElem.setAttribute('id', 'modal-root-loop-health');
            if (document.body.lastElementChild) {
                document.body.insertBefore(rootElem, document.body.lastElementChild.nextElementSibling);
            }
            rootElem.appendChild(modalRef.current);
        }
    }, [modalRef.current]);
    React.useEffect(() => {
        if (!modalRef.current) {
            modalRef.current = document.createElement('div');
        }
    }, []);
    return isVisible && modalRef.current ? (
        createPortal(
            <StyledModalOverlay onClick={() => setIsVisible(false)}>
                <StyledModalContainer
                    onClick={(e) => {
                        e.stopPropagation();
                        // e.preventDefault();
                    }}
                >
                    {title.length > 0 && (
                        <StyledTitleContainer>
                            <StyledTitle>{title}</StyledTitle>
                            <StyledCloseIcon
                                onClick={() => setIsVisible(false)}
                                src={CloseIcon}
                                alt="close add member modal"
                            />
                        </StyledTitleContainer>
                    )}
                    {title.length === 0 && (
                        <StyledAbsoluteCloseIcon
                            onClick={() => setIsVisible(false)}
                            src={CloseIcon}
                            alt="close add member modal"
                        />
                    )}
                    <StyledModalContent>{children}</StyledModalContent>
                </StyledModalContainer>
            </StyledModalOverlay>,
            modalRef.current
        )
    ) : (
        <></>
    );
};

export default Modal;
