import styled from 'styled-components';
import theme from '../../../theme';
import { ITypography } from '../../../theme/types';

export const ContentText = styled.span<{
    $fontSize: string;
    $margin?: string;
    $textAlign?: string;
    $typography?: ITypography;
    $fontWeight?: string;
    $lineHeight?: string;
}>`
    color: ${(props) => (props.color ? props.color : theme.colors.white)};
    margin: ${(p) => p.$margin || '0px'};
    text-align: ${(p) => p.$textAlign || 'start'};
    font-family: ${(p) => p.$typography?.['font-family'] || 'Work Sans'};
    font-size: ${(props) => props.$typography?.['font-size'] || props.$fontSize};
    line-height: ${(p) => p?.$lineHeight || null};
    font-weight: ${(p) => p.$fontWeight || 'normal'};
    word-wrap: break-word;
    @media only screen and (min-width: 375px) and (max-width: 500px) {
        font-size: ${(props) => props.$typography?.['font-size'] || props.$fontSize};
    }
`;
