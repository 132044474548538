interface IRow {
    id: string;
    lable?: string;
    name?: string;
}

export const prepareClaimsFilters = (filters: IRow[]): any => {
    const data: any = [];
    filters?.forEach((filter: any) => {
        const values = filter?.values?.map((row: any) => {
            return { ...row, name: row.label, value: row.id };
        });
        data.push({ ...filter, values });
    });
    return data;
};
