import styled from 'styled-components';
export const StyledCard = styled.div(
    (p) => `
    display: flex;
    flex-direction: column;
    border: 1px solid ${p.theme.colors.muted};
    width: 100%;
    border-radius: 8px;
    box-shadow: 0px 6px 1px ${p.theme.colors.primary};
    background-color: ${p.theme.colors.white};
    padding: 20px;
    margin: 20px 0px;
    box-sizing: border-box;
    position: relative;
`
);
export const StyledFormGroup = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
    margin-right: 5px;
    margin-left: 5px;
    /* min-width: 150px; */
`;
export const StyledFormLabel = styled.div(
    (p) => `
    font-size: ${p.theme.fontSizes.body};
    margin-bottom: 8px;
    margin-left: 5px;
`
);

export const StyledError = styled.span(
    (p) => `
    color: ${p.theme.colors.error};
    margin-left: 5px;
`
);

export const StyledErrorText = styled.span(
    (p) => `
    color: ${p.theme.colors.error};
    margin-left: 5px;
    padding: 5px;
    padding-bottom: 0;
    font-size: ${p.theme.fontSizes.label};
`
);

export const StyledFormField = styled.div(
    (p) => `
    font-size: ${p.theme.fontSizes.body};
    margin-bottom: 8px;
    margin-left: 5px;
`
);
