import styled from 'styled-components';

export const StyledTable = styled.div`
    display: table;
    border-radius: 8px;
    border: 1px solid ${(p) => p.theme.colors.borderLine};
`;
export const StyledTableRow = styled.div(
    (p) => `
    display: table-row;
    width: 100%;
    border-bottom: 1px solid ${p.theme.colors.beyondGrey};    
`
);
export const StyledTableError = styled.div(
    (p) => `
    caption-side: bottom; 
    display: table-caption; 
    text-align: center; 
    background-color: ${p.theme.colors.error};
    color: ${p.theme.colors.white};
    padding: 12px;
`
);
export const StyledTableLoading = styled.div(
    (p) => `
    caption-side: bottom; 
    display: table-caption; 
    text-align: center; 
    background-color: ${p.theme.colors.white};
    padding: 24px;
`
);
export const StyledTableHeading = styled.div<{ $index: boolean; $align: 'left' | 'center' | 'right' }>(
    (p) => `
    display: table-cell;
    background-color: ${p.theme.colors.secondaryBG};
    font-size: ${p.theme.fontSizes.label};
    font-weight:500;
    color: ${p.theme.colors.beyondGrey};
    padding: 15px 18px;
    min-width: 200px;
    vertical-align:middle;
    text-align: ${p.$align};
    border-right: 1px solid ${p.theme.colors.borderLine};
    ${(p.$index && 'width: 60%;') || ''}
`
);
export const StyledTableData = styled.div<{ $index: boolean; $align: 'left' | 'center' | 'right' }>(
    (p) => `
    background-color: ${p.theme.colors.white};
    display: table-cell;
    vertical-align:middle;
    font-size: ${p.theme.fontSizes.body};
    color: ${p.theme.colors.beyondGrey};
    padding: 18px;
    text-align: ${p.$align};
    font-weight:400;
    // max-width: 300px;
    ${(p.$index && 'width: 60%;') || ''}
`
);
