import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './initialState';
import { fetchClaimTpaDetails } from './thunks';

const TpaSlice = createSlice({
    name: 'TpaSlice',
    initialState,
    reducers: {
        resetState(state) {
            state.claimTpaInfo = initialState.claimTpaInfo;
        }
    },
    extraReducers: {
        [fetchClaimTpaDetails.pending as any]: (state) => {
            state.claimTpaInfo.loading = true;
        },
        [fetchClaimTpaDetails.rejected as any]: (state, { error }) => {
            state.claimTpaInfo.error = error;
            state.claimTpaInfo.loading = false;
            state.claimTpaInfo.data = [];
        },
        [fetchClaimTpaDetails.fulfilled as any]: (state, { payload }) => {
            if (typeof payload == 'string') {
                const data = JSON.parse(payload);
                state.claimTpaInfo.data = data;
            } else {
                state.claimTpaInfo.data = payload;
            }
            state.claimTpaInfo.error = null;
            state.claimTpaInfo.loading = false;
        },
        'common/cleanup': (state) => {
            state.claimTpaInfo = initialState.claimTpaInfo;
        }
    }
});
export default TpaSlice.reducer;
export const { resetState } = TpaSlice.actions;
export { fetchClaimTpaDetails };
