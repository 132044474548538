import styled from 'styled-components';

export const StyledContainer = styled.div`
    height: 100%;
    /* padding: 60px; */
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    position: relative;
`;
export const StyledLogo = styled.img`
    max-width: 167px;
    height: auto;
`;

export const StyledImgContainer = styled.div`
    width: 60%;
    padding: 20px 0px;
`;

export const StyledParaContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    margin-top: 105px;
`;
export const StyledHighlight = styled.span`
    color: ${(p) => p.theme.colors.primary};
`;
