import styled from 'styled-components';

export const StyledErrorContainer = styled.div(
    (p) => `
    box-shadow: 0px 2px 26px rgba(215, 0, 0, 0.1);
    border-radius: 8px;
    background-color: ${p.theme.colors.errorBG};
    color: ${p.theme.colors.white};
    font-size: ${p.theme.fontSizes.heading};
    padding: 15px;
    display:flex;
    align-items: center;
    justify-content: space-around;
    margin:30px;
`
);
export const StyledTitle = styled.div`
    font-weight: 600;
`;
export const StyledSubtitle = styled.div`
    font-size: ${(p) => p.theme.fontSizes.body};
    color: ${(p) => p.theme.colors.planRed};
    margin: 0px 10px;
`;

export const StyledCloseIcon = styled.img`
    cursor: pointer;
`;
