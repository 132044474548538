import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './initialState';
import { fetchUserDetail, fetchUserRole } from './thunks';

const userDataSlice = createSlice({
    name: 'userDataSlice',
    initialState,
    reducers: {
        // userDetailByMobileEmail(state,{payload}){
        //     state.UserByPhoneOrMobileDetails.data=state.UserByPhoneOrMobileDetails.data
        // },
        resetUserDataState(state) {
            state.UserByPhoneOrMobileDetails = initialState.UserByPhoneOrMobileDetails;
            state.userPermissions = initialState.userPermissions;
        }
    },
    extraReducers: {
        [fetchUserDetail.pending as any]: (state) => {
            state.UserByPhoneOrMobileDetails.loading = true;
        },
        [fetchUserDetail.rejected as any]: (state, { error }) => {
            state.UserByPhoneOrMobileDetails.error = error;
            state.UserByPhoneOrMobileDetails.loading = false;
            state.UserByPhoneOrMobileDetails.data = null;
        },
        [fetchUserDetail.fulfilled as any]: (state, { payload }) => {
            state.UserByPhoneOrMobileDetails.error = null;
            state.UserByPhoneOrMobileDetails.loading = false;
            state.UserByPhoneOrMobileDetails.data = payload;
        },
        [fetchUserRole.pending as any]: (state) => {
            state.userPermissions.loading = true;
        },
        [fetchUserRole.rejected as any]: (state, { error }) => {
            state.userPermissions.error = error;
            state.userPermissions.loading = false;
            state.userPermissions.data = null;
        },
        [fetchUserRole.fulfilled as any]: (state, { payload }) => {
            state.userPermissions.error = null;
            state.userPermissions.loading = false;
            state.userPermissions.data = payload.userPermissions;
        },
        'common/cleanup': (state) => {
            state.UserByPhoneOrMobileDetails = initialState.UserByPhoneOrMobileDetails;
            state.userPermissions = initialState.userPermissions;
        }
    }
});
export default userDataSlice.reducer;
export const { resetUserDataState } = userDataSlice.actions;
export { fetchUserDetail };
