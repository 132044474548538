import React from 'react';
import { StyledContainer } from './styles';
import { GoogleIcon } from '../../../assets/img';
import { ILoginOAuth } from './types';
import { LoopButton } from '@loophealth/loop-ui-web-library';

const LoginOAuth: React.FunctionComponent<ILoginOAuth> = ({ signInWithOAuth }) => {
    return (
        <StyledContainer>
            <LoopButton variant="outline" onClick={() => signInWithOAuth('google')} iconSrc={GoogleIcon}>
                Continue with Google
            </LoopButton>
        </StyledContainer>
    );
};

export default LoginOAuth;
