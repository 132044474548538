import { createAsyncThunk } from '@reduxjs/toolkit';
import {
    fetchClaimDetailsAPI,
    updateClaimAPI,
    fetchClaimRemarksId,
    updateClaimPriority,
    assignClaim,
    addClaimRemarkAPI,
    fetchClaimRemarksAPI
} from '../../../adapters';

const CLAIM_ACTION_TYPES = {
    FETCH_CLAIM_DETAILS: 'FETCH_CLAIM_DETAILS',
    ADD_CLAIM_REMARK: 'ADD_CLAIM_REMARK',
    FETCH_CLAIM_REMARKS: 'FETCH_CLAIM_REMARKS',
    UPDATE_CLAIM: 'UPDATE_CLAIM',
    ASSIGN_CLAIM_BY_ID: 'ASSIGN_CLAIM_BY_ID',
    UPDATE_CLAIM_PRIORITY: 'UPDATE_CLAIM_PRIORITY',
    ADD_REMARK: 'ADD_REMARK'
};
interface IClaimUpdateType {
    claimId: string;
    polHolderName: string;
    assignTo: string;
    statusName: string;
    priBeneficiaryName: string;
    claimType: string;
}

interface IClaimUpdateType {
    claimId: string;
    polHolderName: string;
    assignTo: string;
    statusName: string;
    priBeneficiaryName: string;
    claimType: string;
}

export const fetchClaimDetailsById = createAsyncThunk(CLAIM_ACTION_TYPES.FETCH_CLAIM_DETAILS, async (id: string) => {
    const res = await fetchClaimDetailsAPI(id);
    return res.data;
});

export const updateClaim = createAsyncThunk(
    CLAIM_ACTION_TYPES.UPDATE_CLAIM,
    async ({ data, id }: { data: Partial<IClaimUpdateType>; id: string }) => {
        const res = await updateClaimAPI(data, id);
        return res;
    }
);

export const fetchClaimRemarksById = createAsyncThunk(CLAIM_ACTION_TYPES.FETCH_CLAIM_REMARKS, async (id: string) => {
    const res = await fetchClaimRemarksId(id);
    return res.data;
});

export const updateClaimPriorityById = createAsyncThunk(
    CLAIM_ACTION_TYPES.UPDATE_CLAIM_PRIORITY,
    async ({ id, priority }: { id: string; priority: string }) => {
        const res = await updateClaimPriority(id, priority);
        return res.data;
    }
);

export const assignClaimById = createAsyncThunk(CLAIM_ACTION_TYPES.ASSIGN_CLAIM_BY_ID, async (id: string) => {
    const res = await assignClaim(id);
    return res.data;
});

export const addClaimRemarkById = createAsyncThunk(
    CLAIM_ACTION_TYPES.ADD_CLAIM_REMARK,
    async ({ id, remark }: { id: any; remark: any }) => {
        const data = {
            remark
        };
        const res = await addClaimRemarkAPI(id, data);
        return res;
    }
);

export const fetchClaimRemarks = createAsyncThunk(CLAIM_ACTION_TYPES.FETCH_CLAIM_REMARKS, async (id: string) => {
    const res = await fetchClaimRemarksAPI(id);
    return res.data;
});
