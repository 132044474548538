import {
    IAgentSortType,
    IAssignmentSuccessResponse,
    IClaimAgent
} from '../../../components/containers/BulkAssignmentModal/types';
import { ITabVariants } from '../../../components/containers/ClaimAssignmentCheckbox/types';
import { IToastContext } from '../../hooks/useToast';

export const assignClaimsToAgents = (claims: string[], agents: IClaimAgent[]): IClaimAgent[] => {
    const agentsData = agents;
    const numClaims = claims.length;
    const selectedAgents = agentsData.filter((agent) => agent.isSelected);
    const numAgents = selectedAgents.length;

    const claimsPerAgent = Math.floor(numClaims / numAgents);
    let remainingClaims = numClaims % numAgents;

    let assignedClaims = 0;
    let agentIndex = 0;

    for (let i = 0; i < numClaims; i++) {
        const claim = claims[i];
        const agent = selectedAgents[agentIndex];

        if (!agent) break;

        if (!agent.claims) {
            agent.claims = [];
        }

        agent.claims.push(claim);
        assignedClaims++;

        if (assignedClaims % claimsPerAgent === 0 && remainingClaims > 0) {
            remainingClaims--;
        }
        agentIndex = (agentIndex + 1) % numAgents;
    }

    return agentsData;
};

export const handleAgentSortByClaimCount = (selectedAgents: IClaimAgent[], sortOrder: string): IClaimAgent[] => {
    const sortedData = [...selectedAgents];
    sortedData?.sort((a: IClaimAgent, b: IClaimAgent) => {
        if (sortOrder === 'asc') {
            return a?.claimCount - b?.claimCount;
        } else {
            return b?.claimCount - a?.claimCount;
        }
    });
    return sortedData;
};

export const getTabVariant = (index: number, length: number, sortOrder: IAgentSortType): ITabVariants => {
    if (index < 3) {
        return sortOrder === 'asc' ? 'error' : 'filled';
    } else if (length - index == 0) {
        return 'default';
    } else if (length - index <= 3) {
        return sortOrder === 'asc' ? 'filled' : 'error';
    }
    return 'default';
};

export const parseResponse = async (promise: Promise<any>): Promise<any> => {
    return promise
        .then((data) => {
            return [null, data];
        })
        .catch((err) => [err]);
};

export const handleToast = (
    error: string,
    response: IAssignmentSuccessResponse,
    toast: IToastContext | null,
    claimsCount: number
): void => {
    if (error) {
        toast?.displayToast('error', ' ', error, 5000);
    }
    if (response.data) {
        toast?.displayToast('success', ' ', `${claimsCount + ' ' + response?.message}`, 5000);
        if (response.data?.failedClaims?.length) {
            toast?.displayToast('error', ' ', `${response.data?.failedClaims?.length} Claims are not assigned`);
        }
    }
};
