import { IUserData, IUserRoleData } from '../redux/slices/UserDataSlice/types';
import { IClaimData } from '../redux/slices/ClaimSlice/types';
import { get, post, put, patch } from './provider';
import { IAgent } from '../redux/slices/ClaimAgentSlice/types';
import { prepareResponse } from '../utils/common/common';

const ENDPOINTS = {
    company: '/company',
    user: '/user',
    agentRole: '/claims/agents/current',
    policy: (policyId: string): string => `/policyPlan/${policyId}`,
    bulk: '/bulk',
    insurer: (insurerName: string): string => `/insurerMasterData/${insurerName}`,
    userPolicies: '/userPolicies',
    createPolicy: '/policyPlan',
    updatePolicy: (policyId: string): string => `/policyPlan/${policyId}`,
    changeRequest: '/userPolicyCR',
    companyWiseChangeRequestStats: '/userPolicyCR/fetchCompanyWiseAggregatedCR',
    bulkUpload: '/claims/uploadcsv',
    claims: '/claims/list',
    claimsFilter: '/claims/filtersByType',
    claimDetails: (id: string): string => `/claims/details/${id}`,
    claimAgent: '/claims/agents',
    claimsHI: '/claims/tpa/status',
    claimsDownload: '/claims/download',
    filters: '/claims/filters',
    claimsPriority: (claimId: string, priority: string) => `/claims/priority/${claimId}/${priority}`,
    claimsStatus: (claimId: string, status: string) => `/claims/status/${claimId}/${status}`,
    claimRemarks: (claimId: string) => `/claims/remarks/${claimId}`,
    assignClaim: (claimId: string, level: string) => `/claims/assign/${claimId}/${level}`,
    fetchFreshdeskChat: (claimId: string) => `/claims/freshdeskChat/${claimId}`,
    claimBulkAssignment: () => '/claims/bulkAssign',
    claimBulkUnAssignment: () => '/claims/bulkUnAssign',
    tpa: '/tpa/list'
};

const fetchAllClaimsAPI = async (data: Record<string, any>): Promise<IClaimData[]> => {
    const result = await post(ENDPOINTS.claims, data);
    return ((result.data as any) as unknown) as IClaimData[];
};

const fetchClaimStatusOptionsAPI = async (statusType = 'Action Taken'): Promise<IClaimData[]> => {
    const queryParams = new URLSearchParams({
        name: statusType
    }).toString();
    const result = await get(ENDPOINTS.claimsFilter + `?${queryParams}`);
    return (result.data as any).filters as any;
};

const bulkUpload = async (data: Record<string, any>): Promise<any> => {
    const response: any = await post(ENDPOINTS.bulkUpload, data);
    return response;
};

const createClaimAPI = async (data: Record<string, unknown>): Promise<IClaimData> => {
    const response: any = await post(ENDPOINTS.claims, { ...data });
    return response.data as any;
};

const updateClaimAPI = async (data: Record<string, any>, id: string): Promise<any> => {
    const route = `${ENDPOINTS.claims}${id}`;
    const response: any = await put(route, data);
    return response?.data;
};

const fetchUsersByEmailOrMobileAPI = async (attribute: string, value: string): Promise<IUserData> => {
    const result = await get(
        ENDPOINTS.user + '/fetchUserWithPoliciesByMobileOrEmail' + '?' + `${attribute}=${value.trim()}`
    );
    return ((result.data as any).user as unknown) as IUserData;
};
const fetchPolicyAPI = async (policyId: string): Promise<any> => {
    const policy: any = await get(ENDPOINTS.policy(policyId));
    return policy;
};

const fetchClaimDetailsAPI = async (id: string): Promise<any> => {
    const claims: any = await get(ENDPOINTS.claimDetails(id));
    return claims;
};

const fetchAllCompaniesAPI = async (): Promise<any> => {
    const companiesData: any = await get(`${ENDPOINTS.company}?active=true`);
    return companiesData;
};

const fetchAllClaimAgentsAPI = async (): Promise<any> => {
    try {
        const claimAgentData = await get(`${ENDPOINTS.claimAgent}`);
        return ((claimAgentData as any).data.agents ?? []) as IAgent[];
    } catch (e) {
        throw e;
    }
};

const fetchAgentsRoleAPI = async (): Promise<any> => {
    try {
        const agentData = await get(`${ENDPOINTS.agentRole}`);
        return (agentData as any).data.agents as IUserRoleData;
    } catch (e) {
        throw e;
    }
};
const claimsAssignment = async (req: Record<string, unknown>): Promise<any> => {
    try {
        const result = await post(`${ENDPOINTS.claimBulkAssignment()}`, req);
        return result as any;
    } catch (e) {
        throw e;
    }
};

const claimsUnAssignment = async (req: Record<string, unknown>): Promise<any> => {
    try {
        const result = await post(`${ENDPOINTS.claimBulkUnAssignment()}`, req);
        return result as any;
    } catch (e) {
        throw e;
    }
};

const fetchClaimTpaDetailsAPI = async (data: Record<string, any>): Promise<Record<string, unknown>> => {
    const queryParams = new URLSearchParams({
        ...data
    }).toString();
    const result: any = await get(`${ENDPOINTS.claimsHI}?${queryParams}`);
    return ((result.data as any).status as unknown) as any;
};

const downloadClaimsAPI = async (): Promise<any> => {
    const result: any = await post(`${ENDPOINTS.claimsDownload}`, {});
    return result;
};

const claimsFiltersAPI = async (): Promise<any> => {
    const result: any = await get(`${ENDPOINTS.filters}`);
    return result;
};

const updateClaimPriority = async (claimId: string, priority: string): Promise<any> => {
    const route = `${ENDPOINTS.claimsPriority(claimId, priority)}`;
    const response: any = await patch(route);
    return response?.data;
};

const updateClaimStatusAPI = async (claimId: string, status: string): Promise<any> => {
    const route = `${ENDPOINTS.claimsStatus(claimId, status)}`;
    const response: any = await patch(route);
    const responseData = prepareResponse(response);
    return responseData;
};

const fetchClaimRemarksId = async (claimId: string): Promise<any> => {
    const result: any = await get(`${ENDPOINTS.claimRemarks(claimId)}`);
    return result;
};

const fetchFreshdeskChatAPI = async (id: string): Promise<any> => {
    const result: any = await get(ENDPOINTS.fetchFreshdeskChat(id));
    return result;
};
const addClaimRemarkAPI = async (claimId: string, data: Record<string, any>): Promise<any> => {
    const route = `${ENDPOINTS.claimRemarks(claimId)}`;
    const response: any = await post(route, data);
    const responseData = prepareResponse(response);
    return responseData;
};

const fetchClaimRemarksAPI = async (claimId: string): Promise<any> => {
    const remarks: any = await get(ENDPOINTS.claimRemarks(claimId));
    return remarks;
};

const assignClaim = async (claimId: string, level = 'L2'): Promise<any> => {
    const route = `${ENDPOINTS.assignClaim(claimId, level)}`;
    const response: any = await patch(route);
    return response?.data;
};

const fetchAllTpaAPI = async (): Promise<any> => {
    const tpaListData: any = await get(ENDPOINTS.tpa);
    return tpaListData;
};

export {
    fetchAllClaimsAPI,
    createClaimAPI,
    fetchUsersByEmailOrMobileAPI,
    fetchPolicyAPI,
    bulkUpload,
    updateClaimAPI,
    fetchAllCompaniesAPI,
    fetchAllClaimAgentsAPI,
    fetchClaimTpaDetailsAPI,
    fetchClaimDetailsAPI,
    downloadClaimsAPI,
    claimsFiltersAPI,
    updateClaimPriority,
    fetchClaimRemarksId,
    fetchClaimStatusOptionsAPI,
    updateClaimStatusAPI,
    fetchClaimRemarksAPI,
    fetchFreshdeskChatAPI,
    assignClaim,
    addClaimRemarkAPI,
    claimsAssignment,
    fetchAgentsRoleAPI,
    fetchAllTpaAPI,
    claimsUnAssignment
};
