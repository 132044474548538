import moment from 'moment';

/**
 * Formats a given date according to the specified format.
 *
 * @param {any} date - The date to format.
 * @param {string} [format='DD/MM/YY'] - The format string for the desired date format.
 * @return {string} The formatted date string.
 */
export const formatDate = (date: Date, format = 'DD/MM/YY'): string => {
    return moment(date).format(format);
};

export const addLeadingZeroes = (n: number, zeroCount = 2): string => ('0'.repeat(zeroCount) + n).slice(-zeroCount);

export const defaultDateFormat = (seconds: number, options?: Intl.DateTimeFormatOptions | undefined): string => {
    const date = new Date(seconds);
    if (!options) {
        return (
            addLeadingZeroes(date.getDate()) +
            '/' +
            addLeadingZeroes(date.getMonth() + 1) +
            '/' +
            addLeadingZeroes(date.getFullYear(), 4)
        );
    }
    return date.toLocaleDateString('en-US');
};

export const getFormattedDate = (value: any | undefined, options?: Intl.DateTimeFormatOptions | undefined): string =>
    value ? defaultDateFormat(value, options) : '-';

export const getClaimAge = (value: string): number => {
    if (!value) return 0;
    const date: Date = new Date(value);
    const days = Date.now() - date.getTime();
    const age = Math.floor(days / (1000 * 3600 * 24));
    return age;
};
export const getAgeFromDOB = (userDob: number): number => {
    const dob = new Date(userDob * 1000);
    const monthDiff = Date.now() - dob.getTime();
    const ageDt = new Date(monthDiff);
    const year = ageDt.getUTCFullYear();
    return Math.abs(year - 1970);
};

export const dayStartTime = (date: string): Date => {
    return moment(date).startOf('day').toDate();
};

export const dayEndTime = (date: string): Date => {
    return moment(date).endOf('day').toDate();
};

export const getFormattedFiltersDate = (date: Date, type: string): string => {
    return type === 'End' ?
        moment(date).add({ hours: 23, minutes: 59, seconds: 59 }).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]') :
        moment(date).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
};
